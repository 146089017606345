import React, { useState, useEffect } from 'react';
// mui
import {
    TableRow,
    TextField,
    IconButton,
    Table,
    TableBody,
    TableHead,
    TableCell,
    Divider,
    Stack,
    Tooltip,
    Zoom,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import NightlightIcon from '@mui/icons-material/Nightlight';
import SendIcon from '@mui/icons-material/Send';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
//components
import { STATUTS_COLOR } from 'config/index'
import TableMobileDatePicker from 'components/UI/TableInput/TableMobileDatePicker'
import moment from 'moment';
import Mailbox from 'views/Mail/Mail';
import Modal from "components/UI/Modal";
import axios from 'axios';

// ----------------------------------------------------------------------

const StyledTableCellHeader = styled(TableCell)(({ theme }) => ({
    whiteSpace: 'nowrap',
    padding: "5px 10px",
    borderBottom: "none",
    fontWeight: "bold",
    color: "white",
    height: '30px',
}));

const StyledTableCell = styled(TableCell)(() => ({
    padding: "5px 10px",
    maxHeight: "30px !important",
    height: "30px !important",
    borderBottom: "none",
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
    width: "100%",
    backgroundColor: "white",
    "input": {
        padding: "5px 10px",
    }
}));

// ----------------------------------------------------------------------

export default function TableDateIntervention({ action, disabled, newDateIntervention, modifyDateIntervention, deleteDateIntervention, infoIntervention, user }) {
    const [openEmailModal, setOpenEmailModal] = useState(false);
    const [attachment, setAttachment] = useState(null);
    const [commentaire, setCommentaire] = useState("");

    const handleCloseEmailModal = () => {
        setOpenEmailModal(false);
    };

    const getCommentaire = async () => {
        try {
            axios.defaults.withCredentials = true;
            const response = await axios.get(`/legacy/getComments?id_mastore=${infoIntervention.id_mastore}`);
            
            const lastComment = response.data.comments
                .reverse()
                .find(comment => comment.commentaire.includes("Un email a été envoyé"));

    
            setCommentaire(lastComment?.commentaire || "Aucun commentaire disponible");
        } catch (error) {
            console.error("Erreur lors de la récupération du commentaire :", error);
            setCommentaire("Erreur lors de la récupération du commentaire");
        }
    };    

    useEffect(() => {
        getCommentaire();
    }, [infoIntervention.id_mastore]);

    const updateCommentaire = (newCommentaire) => {
        setCommentaire(newCommentaire);
    };

    const handleOpenEmailModal = () => {
        setOpenEmailModal(true);
    };

    const customObject = `Intervention Mastore pour : ${infoIntervention.objet} prévue le ${moment(action.date_intervention[0].date).format("DD MMMM YYYY")} - ${infoIntervention.enseigne} N°${infoIntervention.numero_magasin} - DI Mastore ${infoIntervention.id_mastore} - DI Client ${infoIntervention.id_client} - ${infoIntervention.ville} [${infoIntervention.code_postal}] - ${infoIntervention.specialite}`

    return (
        <TableRow>
            <TableCell colSpan={9}>
                <Table size="small">
                    <TableHead>
                        {/* Header */}
                        <TableRow>
                            <StyledTableCell colSpan={1} style={{ border: "unset", padding: "0px 10px", background: 'white' }}>
                                <Divider orientation="vertical" style={{ margin: "auto", background: STATUTS_COLOR[action.etat], width: '1px', height: "100%" }} flexItem />
                            </StyledTableCell>
                            <StyledTableCellHeader align='center' style={{ background: STATUTS_COLOR[action.etat], width: "25%", borderTopLeftRadius: "5px" }}>Date d'intervention</StyledTableCellHeader>
                            <StyledTableCellHeader align="center" style={{ background: STATUTS_COLOR[action.etat], width: "18%" }}>Heure</StyledTableCellHeader>
                            <StyledTableCellHeader align="center" style={{ background: STATUTS_COLOR[action.etat], width: "18%" }}>Durée</StyledTableCellHeader>
                            <StyledTableCellHeader align="center" style={{ background: STATUTS_COLOR[action.etat], width: "10%" }}>Nuit</StyledTableCellHeader>
                            <StyledTableCellHeader align="center" style={{ background: STATUTS_COLOR[action.etat], width: "10%" }}>IS</StyledTableCellHeader>
                            <StyledTableCell align='center' style={{ background: STATUTS_COLOR[action.etat], width: "10%" }}>
                                <IconButton
                                    aria-label="add"
                                    onClick={() => newDateIntervention(action)}
                                    disabled={disabled}
                                    style={{ color: "white" }}
                                >
                                    <MoreTimeIcon />
                                </IconButton>
                            </StyledTableCell>
                            <StyledTableCellHeader align='center' style={{ background: STATUTS_COLOR[action.etat], width: "5%" }}>Envoyer</StyledTableCellHeader>
                            <StyledTableCellHeader align="center" style={{ background: STATUTS_COLOR[action.etat], width: "10%", borderTopRightRadius: "5px" }}>
                              Infos
                            </StyledTableCellHeader>
                        </TableRow>
                    </TableHead>

                    {/* Row Date intervention d'une action*/}
                    <TableBody>
                        {action.date_intervention.map((date_intervention, index) => (
                            <TableRow key={index}>
                                <StyledTableCell style={{ padding: "0px 10px" }}>
                                    <Divider orientation="vertical" style={{ margin: "auto", background: STATUTS_COLOR[action.etat], width: '1px', height: "100%" }} flexItem />
                                </StyledTableCell>
                                <StyledTableCell style={{ width: "130px" }}>
                                    <Stack direction="row" spacing={1} justifyContent="left" alignItems="center">
                                        {date_intervention.nuit ? <NightlightIcon color="primary" /> : null}

                                        <TableMobileDatePicker
                                            inputFormat="dddd DD MMM"
                                            value={moment(date_intervention.date)}
                                            disabled={disabled}
                                            onChange={(value) => modifyDateIntervention('date', value, action.ID, date_intervention.ID)}
                                        />
                                    </Stack>
                                </StyledTableCell>
                                <StyledTableCell align="center" style={{ width: "55px" }}>
                                    <StyledTextField
                                        size="small"
                                        variant="outlined"
                                        id="duration"
                                        type="time"
                                        defaultValue={new Date(date_intervention.date).toLocaleTimeString("fr-FR", { hour: "numeric", minute: "numeric" })}
                                        disabled={disabled}
                                        onChange={(event) => {
                                            const strCopy = event.target.value.split(':')
                                            const newDate = new Date(date_intervention.date)
                                            if (strCopy.length === 2) {
                                                newDate.setHours(parseInt(strCopy[0], 10), parseInt(strCopy[1], 10), 0)
                                            }
                                            modifyDateIntervention('date', newDate, action.ID, date_intervention.ID)
                                        }}
                                        inputProps={{
                                            step: 900, // 15 min
                                        }}
                                    />
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    <StyledTextField
                                        size="small"
                                        id="duration"
                                        variant="outlined"
                                        type="time"
                                        defaultValue={date_intervention.duration}
                                        disabled={disabled}
                                        onChange={(event) => modifyDateIntervention('duration', event.target.value, action.ID, date_intervention.ID)}
                                        inputProps={{
                                            step: 1800, // 30 min
                                        }}
                                    />
                                </StyledTableCell>
                                <StyledTableCell align="center" style={{ width: "55px" }}>
                                    <Checkbox
                                        checked={date_intervention.nuit}
                                        onChange={(event) => modifyDateIntervention('nuit', event.target.checked, action.ID, date_intervention.ID)}
                                        disabled={disabled}
                                        name="nuit"
                                    />
                                </StyledTableCell>
                                <StyledTableCell align="center" style={{ width: "55px" }}>
                                    <Checkbox
                                        checked={date_intervention.info_site}
                                        onChange={(event) => modifyDateIntervention('info_site', event.target.checked, action.ID, date_intervention.ID)}
                                        disabled={disabled}
                                        name="info_site"
                                    />
                                </StyledTableCell>
                                <StyledTableCell align='center' style={{ width: "30px" }}>
                                    <IconButton
                                        aria-label="add"
                                        onClick={() => deleteDateIntervention(action.ID, date_intervention.ID)}
                                        disabled={disabled}
                                        size="small">
                                        <DeleteIcon color='error' />
                                    </IconButton>
                                </StyledTableCell>
                                <StyledTableCell align='center' style={{ width: "20px" }}>
                                    <IconButton
                                        aria-label="add"
                                        onClick={handleOpenEmailModal}
                                        disabled={action.etat !== "A SUIVRE"}
                                        size="small">
                                        <SendIcon color={action.etat === "A SUIVRE" ? "success" : "inherit"}/>
                                    </IconButton>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {action.etat === "A SUIVRE" ? (
                                        <Tooltip TransitionComponent={Zoom} title={commentaire}>
                                            <IconButton size="small">
                                                <InfoOutlinedIcon color="primary" />
                                            </IconButton>
                                        </Tooltip>
                                    ) : (
                                        <IconButton size="small" disabled>
                                            <InfoOutlinedIcon color="inherit" />
                                        </IconButton>
                                    )}
                                </StyledTableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableCell>
            <Modal sx={{}} open={openEmailModal} close={handleCloseEmailModal} clickOut={false} >
                <Mailbox
                    sendto="client"
                    mailuser={user.mail}
                    dateIntervention={
                        action.date_intervention.length > 0 
                            ? action.date_intervention[0].date 
                            : null
                    }
                    duration={
                        action.date_intervention.length > 0 
                            ? action.date_intervention[0].duration 
                            : null
                    }
                    attribut="Mail prévention"
                    objectMail={customObject}
                    Attachment={attachment}
                    destinataire={infoIntervention.contact_client}
                    infoIntervention={infoIntervention}
                    modalClosed={handleCloseEmailModal}
                    updateCommentaire={updateCommentaire}
                />
            </Modal>
        </TableRow >
    )
}