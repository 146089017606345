import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';
import { connect } from 'react-redux';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Iconify from "../Iconify";
import Scrollbar from "../Scrollbar";
import Header from "../Header/Header";
import FlexFooter from "../FlexFooter";
import Dropzone from 'components/UI/DropZoneCompress';
import PreviewDropzone from 'components/UI/PreviewDropzone';
import { ResizeImage, FileToB64 } from 'components/UI/ResizeImage';
import MultiFilePreview from "components/UI/upload/MultiFilePreview";
import FormHelperText from '@mui/material/FormHelperText';
import moment from 'moment';
//mui
import { styled } from '@mui/material/styles';
import {
    Grid,
    TextField,
    Button,
    Autocomplete,
    FormControlLabel,
    Checkbox,
    InputLabel,
    Select,
    FormControl,
    MenuItem,
} from '@mui/material';
import {
    GetSpecialite,
    PostDemandeInterventionClient,
    PostDemandeIntervention,
    GetAllEnseigne,
    GetMagasinByEnseigne,
    GetContactByClient,
    GetPilote,
} from "services";
import { groupBy, filter } from "lodash";
import CustomEditor from 'components/UI/CustomEditor';

const MenuItemStyle = styled(MenuItem)(({ theme }) => ({
    paddingLeft: theme.spacing(1.5),
    fontWeight: "bold",
}));

const schema = Yup.object().shape({
    objet: Yup.string().required("Veuillez renseigner l'objet"),
    specialite: Yup.string().required("Veuillez renseigner la spécialité"),
    initial_request: Yup.string().required("Veuillez renseigner la demande initiale"),
    pilote: Yup.number().positive().required(""),
    contact_client: Yup.string().required(""),
    date_demande: Yup.date(),
    urgency: Yup.string().required("Veuillez sélectionner le niveau d'urgence"),
}).required();

const interventions = {
    id_client: '',
    enseigne: '',
    client: '',
    objet: '',
    complement_objet: 'PRESTATION À REALISER :\n',
    initial_request: 'DEMANDE CLIENT :\n',
    contact_client: "select",
    date_demande: new Date(),
    date_max: new Date(),
    ville: '',
    pays: '',
    code_postal: '',
    adresse: '',
    complement_adresse: '',
    implantation: '',
    mail: '',
    telephone: '',
    numero_magasin: '',
    specialite: '',
    pilote: -1,
    urgency: 'non-urgent', // Default value for urgency
}

function FormDemandeIntervention({ intervention = interventions, ModalFor, OnCloseModal, attached_to }) {
    const { enqueueSnackbar } = useSnackbar();
    const [pilotes, setPilotes] = useState([]);
    const [idClient, setIdClient] = useState(false);
    const [parametres, setParametres] = useState([]);
    const [contactByClient, setContactByClient] = useState([]);
    const [enseignes, setEnseignes] = useState([]);
    const [sites, setSites] = useState([]);
    const [pdf, setPdf] = useState([]);
    const [photos, setPhotos] = useState([]);

    const fetchInitialData = async () => {
        try {
            if (ModalFor === "demandeIntervention") {
                const [
                    responseAllEnseigne,
                    responseSpecialite,
                    pilotes
                ] = await Promise.all([
                    GetAllEnseigne(),
                    GetSpecialite(),
                    GetPilote(),
                ]);

                setParametres(responseSpecialite.parametres);
                setEnseignes(responseAllEnseigne.enseigne);
                setPilotes(groupBy(pilotes.pilotes, item => item.entreprise));
                setValue("pilote", attached_to);
            } else {
                const [
                    responseContactClient,
                    responseSpecialite,
                    pilotes
                ] = await Promise.all([
                    GetContactByClient(intervention.client),
                    GetSpecialite(),
                    GetPilote(),
                ]);

                setContactByClient(responseContactClient.contact);
                setParametres(responseSpecialite.parametres);
                setPilotes(groupBy(pilotes.pilotes, item => item.entreprise));
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchInitialData();
    }, []);

    const { register, handleSubmit, control, setValue, formState: { errors } } = useForm(
        { resolver: yupResolver(schema), defaultValues: intervention }
    );

    const handleSite = (site) => {
        setValue("code_postal", site.code_postal);
        setValue("adresse", site.adresse);
        setValue("numero_magasin", site.numero_magasin);
        setValue("telephone", site.telephone);
        setValue("ville", site.ville);
        setValue("pays", site.pays);
        setValue("complement_adresse", site.complement_adresse);
        setValue("mail", site.mail);
        setValue("client", site.client);
        setValue("implantation", site.implantation);
        GetContactByClient(site.client).then((response) => {
            setContactByClient(filter(response.contact, { 'archiver': false }));
        }).catch((error) => {
            console.error(error);
        });
    };

    const handleSelectedEnseigneAndSetSite = (selectedEnseigne) => {
        setValue("enseigne", selectedEnseigne);
        GetMagasinByEnseigne(selectedEnseigne).then((response) => {
            setSites(filter(response.site, { 'archiver': false }));
        }).catch(err => {
            console.error(err);
        });
    };

    const capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    const onSubmitForm = (inputUser) => {
        const handleSuccess = (response) => {
            window.open("/intervention?IDMastore=" + response.intervention.id_mastore);
            OnCloseModal();
            enqueueSnackbar('Votre demande a été envoyé', {
                variant: 'success',
            });
        };

        const handleError = (err) => {
            enqueueSnackbar('Les informations envoyées sont incorrectes', {
                variant: 'error',
            });
            console.error(err);
        };

        inputUser.objet = capitalize(inputUser.objet.trim().replace(/[&/\\#,+()$~%.'":*?<>{}]/g, "").toLowerCase());
        if (ModalFor === "demandeIntervention") {
            inputUser.pdf = pdf;
            inputUser.photo = photos;
            PostDemandeIntervention(inputUser).then(handleSuccess).catch(handleError);
        } else {
            PostDemandeInterventionClient(inputUser).then((response) => {
                window.open("/intervention?IDMastore=" + response.data.id_mastore);
                window.location.reload();
                handleSuccess(response);
            }).catch(handleError);
        }
    };

    const handlePdf = async (files) => {
        const base64Files = await Promise.all(files.map(async file => {
            const base64 = await FileToB64(file);
            return { file: base64, type: "pdf", name: file.path };
        }));
        setPdf(prevState => prevState.concat(base64Files));
    };

    const handlePhoto = async (files) => {
        const base64Files = await Promise.all(files.map(async file => {
            const base64 = await ResizeImage(file);
            return { file: base64, type: "image", name: file.path };
        }));
        setPhotos(prevState => prevState.concat(base64Files));
    };

    const handleRemove = (file) => {
        setPhotos(photos.filter(_file => _file !== file));
    };

    const handleRemovePdf = (file) => {
        setPdf(pdf.filter(_file => _file !== file));
    };

    return (
        <form onSubmit={handleSubmit(onSubmitForm)} style={{ height: "100%", flexFlow: 'column', display: "flex" }}>
            <Header>Nouvelle intervention</Header>
            <Scrollbar sx={{ flex: '1 1 auto' }}>
                <Grid container spacing={1} sx={{ px: { md: 3, xs: 2 }, mt: 0.5 }}>
                    <Grid item xs={12}>
                        <Controller
                            control={control}
                            name="date_demande"
                            render={({ field: { onChange, value } }) => (
                                <DatePicker
                                    disableCloseOnSelect={false}
                                    showToolbar={false}
                                    label="Date de demande"
                                    inputFormat="DD MMMM YYYY"
                                    disableMaskedInput
                                    value={moment(value)}
                                    onChange={onChange}
                                    slotProps={{
                                        textField: {
                                            size: "small",
                                            style: { width: "210px" },
                                            InputLabelProps: { shrink: true },
                                        },
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            control={control}
                            name="pilote"
                            render={({ field: { onChange, value } }) => (
                                <FormControl style={{ width: "210px" }}>
                                    <InputLabel shrink variant="outlined">Contact Mastore</InputLabel>
                                    <Select
                                        onChange={onChange}
                                        label="Contact Mastore"
                                        size="small"
                                        value={value}
                                    >
                                        <MenuItem value={-1}>Sélectionner un pilote</MenuItem>
                                        <MenuItemStyle disabled>MASTORE OUEST</MenuItemStyle>
                                        {pilotes["Mastore Ouest"]?.map(pilote => (
                                            <MenuItem key={pilote.ID} value={pilote.ID}>{pilote.firstname} {pilote.lastname}</MenuItem>
                                        ))}
                                        <MenuItemStyle disabled>MASTORE</MenuItemStyle>
                                        {pilotes["Mastore"]?.map(pilote => (
                                            <MenuItem key={pilote.ID} value={pilote.ID}>{pilote.firstname} {pilote.lastname}</MenuItem>
                                        ))}
                                    </Select>
                                    {errors.pilote && <FormHelperText error>Veuillez renseigner le pilote</FormHelperText>}
                                </FormControl>
                            )}
                        />
                    </Grid>
                    <Grid item md={8} xs={12} sx={{ flexFlow: 'row', display: 'flex' }}>
                        <TextField
                            label="ID Client"
                            variant="outlined"
                            type="text"
                            {...register("id_client")}
                            size="small"
                            disabled={idClient}
                            InputLabelProps={{ shrink: true }}
                            sx={{ flex: "1 1 0" }}
                        />
                        <FormControlLabel
                            sx={{ ml: 1 }}
                            control={
                                <Checkbox
                                    checked={idClient}
                                    onChange={() => {
                                        setValue("id_client", "SO");
                                        setIdClient(!idClient);
                                    }}
                                    name="SansObjet"
                                    style={{ padding: "5px" }}
                                    color="primary"
                                />
                            }
                            label="Sans objet"
                        />
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <Controller
                            name="specialite"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <Autocomplete
                                    size="small"
                                    options={parametres}
                                    getOptionLabel={(option) => option.parametre1}
                                    onChange={(event, value) => onChange(value.parametre1)}
                                    renderInput={(params) => <TextField size="small" placeholder="Sélectionnez une specialité" required name="spécialité" {...params} label="Spécialité" InputLabelProps={{ shrink: true }} />}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField size="small" fullWidth {...register("objet")} name="objet" type="text" label="Objet de la demande d'intervention" helperText={errors.objet?.message} defaultValue={intervention.objet} InputLabelProps={{ shrink: true }} />
                    </Grid >
                    <Grid item xs={12}>
                        <Grid item container spacing={0.5}>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <TextField
                                    size="small"
                                    fullWidth
                                    minRows={9}
                                    maxRows={12}
                                    multiline
                                    {...register("initial_request")}
                                    name="initial_request"
                                    type="text"
                                    label='Demande Initiale'
                                    defaultValue={ModalFor === "demandeIntervention" ? "" : intervention.status + " :\n\n" + intervention.initial_request}
                                    helperText={errors.initial_request?.message}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <CustomEditor
                                    initialData={intervention.complement_objet}
                                    setInitialData={(updatedData) => setValue("complement_objet", updatedData)}
                                    onSave={(updatedData) => setValue("complement_objet", updatedData)}
                                    placeholder="Complément d'objet"
                                    customStyles={{
                                        height: "125px",
                                        overflowY: "auto",
                                        border: "1px solid rgb(196 196 196)",
                                        borderRadius: "4px",
                                        background: "#fff",
                                        flexGrow: 1,
                                        fontSize: "8px",
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            name="enseigne"
                            control={control}
                            render={({ fieldState: { error } }) => (
                                <Autocomplete
                                    size="small"
                                    options={enseignes}
                                    defaultValue={intervention.enseigne}
                                    disabled={ModalFor === "demandeIntervention" ? false : true}
                                    onChange={(event, value) => handleSelectedEnseigneAndSetSite(value)}
                                    fullWidth
                                    renderInput={(params) => <TextField
                                        size="small"
                                        required
                                        error={!!error}
                                        helperText={error?.message}
                                        name="enseigne"
                                        {...params}
                                        label="Enseigne"
                                        InputLabelProps={{ shrink: true }}
                                    />}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            disablePortal
                            size="small"
                            options={sites}
                            defaultValue={ModalFor === "demandeIntervention" ? null : intervention}
                            disabled={ModalFor === "demandeIntervention" ? false : true}
                            getOptionLabel={(option) => {
                                return (`${(option.ville).toUpperCase()} ${option.code_postal} - ${option.adresse} [N˚${option.numero_magasin}]`);
                            }}
                            fullWidth
                            onChange={(event, value) => handleSite(value)}
                            renderInput={(params) => <TextField
                                size="small"
                                required
                                name="magasin"
                                {...params}
                                label="Site"
                                InputLabelProps={{ shrink: true }}
                            />}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            control={control}
                            name="contact_client"
                            render={({ field: { onChange, value } }) => (
                                <FormControl style={{ width: "210px" }}>
                                    <InputLabel shrink variant="outlined">Contact Client</InputLabel>
                                    <Select
                                        onChange={onChange}
                                        label="Contact Client"
                                        size="small"
                                        value={value}
                                    >
                                        <MenuItem value="select">Sélectionner un contact</MenuItem>
                                        {contactByClient.map(item => (
                                            <MenuItem key={item.ID} value={`${item.prenom} ${item.nom}`}>{`${item.prenom} ${item.nom}`}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            control={control}
                            name="urgency"
                            render={({ field: { onChange, value } }) => (
                                <FormControl style={{ width: "210px" }}>
                                    <InputLabel shrink variant="outlined">Niveau de criticité</InputLabel>
                                    <Select
                                        onChange={onChange}
                                        label="Niveau de criticité"
                                        size="small"
                                        value={value}
                                    >
                                        <MenuItem value="non-urgent">Non urgent</MenuItem>
                                        <MenuItem value="standard">Standard</MenuItem>
                                        <MenuItem value="urgent">Urgent</MenuItem>
                                        <MenuItem value="critique">Critique</MenuItem>
                                    </Select>
                                    {errors.urgency && <FormHelperText error >{errors.urgency.message}</FormHelperText>}
                                </FormControl>
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            control={control}
                            name="date_max"
                            render={({ field: { value, onChange } }) => (
                                <DatePicker
                                    disableCloseOnSelect={false}
                                    showToolbar={false}
                                    label="Date au plus tard"
                                    inputFormat="DD MMMM yyyy"
                                    disableMaskedInput
                                    value={moment(value)}
                                    onChange={onChange}
                                    slotProps={{
                                        textField: {
                                            size: "small",
                                            style: { width: "210px" },
                                            InputLabelProps: { shrink: true },
                                        },
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    {
                        ModalFor === "demandeIntervention" && (
                            <>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <Dropzone returnFunction={handlePhoto} image={true} pdf={false} icon={<Iconify icon={"material-symbols:photo-camera-outline"} />} text={"Sélectionnez vos photos"} />
                                    {photos.length > 0 && <MultiFilePreview files={photos} showPreview={photos} onRemove={handleRemove} />}
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <Dropzone returnFunction={handlePdf} image={false} pdf={true} icon={<Iconify icon={"carbon:document"} />} text={"Sélectionnez vos documents"} />
                                    {pdf.length > 0 && <PreviewDropzone files={pdf} type="pdf" onRemove={handleRemovePdf} />}
                                </Grid>
                            </>
                        )
                    }
                </Grid >
            </Scrollbar >
            <FlexFooter>
                <Button type="submit" variant="outlined" style={{ width: { md: "30%", xs: '40%' } }}>
                    Créer l'intervention
                </Button>
            </FlexFooter>
        </form >
    );
}

const mapStateToProps = state => {
    return {
        role: state.role,
        enseigne: state.enseigne,
        adresse: state.adresse,
        attached_to: state.attached_to
    };
};

export default connect(mapStateToProps, null)(FormDemandeIntervention);
