import React from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  Alert as MuiAlert,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ImageIcon from '@mui/icons-material/Image';
import PublishIcon from '@mui/icons-material/Publish';
import { styled } from '@mui/material/styles';
import axios from 'axios';

import Spinner from '../../../UI/Spinner/Spinner';
import { b64toBlob } from 'utils/handleFiles';

const StyledTableContainer = styled(TableContainer)(() => ({
  maxHeight: 330,
  minHeight: 70,
}));

const IconPublish = styled(PublishIcon)(() => ({
  color: '#349034ed',
  cursor: 'pointer',
  '&:hover': {
    color: '#1b5e20',
  },
}));

const IconDelete = styled(DeleteIcon)(() => ({
  color: '#FF0000',
  cursor: 'pointer',
  '&:hover': {
    color: '#DD0000',
  },
}));

const IconImage = styled(ImageIcon)(() => ({
  color: '#316690',
  cursor: 'pointer',
}));

const PreviewStyleDialog = styled('img')(() => ({
  display: 'inline',
  width: 200,
  height: 200,
  transition: 'transform 2.2s',
  marginLeft: 'auto',
  marginRight: 'auto',
  border: '1px solid black',
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const mockData = [
  {
    ID: 1,
    Name: 'Éclairage Principal',
    Zone: 'Entrée',
    Activite: 'Vérification',
    Objet: "Système d'éclairage",
    Photo: 'eclairage1.png',
    Marque: 'Philips',
    Type: 'LED',
    Reference: 'LED-001',
    Commentaire: 'Fonctionne parfaitement',
    File_id: 101,
    Quantite: 20,
    Etats: 1,
    Conformité: 1,
    Criticité: 2,
    Maintenabilité: 3,
  },
  {
    ID: 2,
    Name: 'Climatisation',
    Zone: 'Salle de Vente',
    Activite: 'Entretien',
    Objet: 'Système de climatisation',
    Photo: 'climatisation1.png',
    Marque: 'Daikin',
    Type: 'Split',
    Reference: 'DA-CLIM-002',
    Commentaire: 'Nettoyage des filtres effectué',
    File_id: 102,
    Quantite: 5,
    Etats: 1,
    Conformité: 1,
    Criticité: 1,
    Maintenabilité: 2,
  },
  {
    ID: 3,
    Name: 'Système de Sécurité',
    Zone: 'Entrée',
    Activite: 'Inspection',
    Objet: 'Caméra de sécurité',
    Photo: 'camera1.png',
    Marque: 'Hikvision',
    Type: 'CCTV',
    Reference: 'CCTV-003',
    Commentaire: 'Aucune anomalie détectée',
    File_id: 103,
    Quantite: 10,
    Etats: 1,
    Conformité: 1,
    Criticité: 3,
    Maintenabilité: 2,
  },
  {
    ID: 4,
    Name: 'Rayonnage',
    Zone: 'Stock',
    Activite: 'Réparation',
    Objet: 'Étagères',
    Photo: 'rayonnage1.png',
    Marque: 'Ikea',
    Type: 'Métal',
    Reference: 'IK-RAY-004',
    Commentaire: 'Réparations mineures nécessaires',
    File_id: 104,
    Quantite: 15,
    Etats: 2,
    Conformité: 1,
    Criticité: 2,
    Maintenabilité: 1,
  },
];

export default function DenseTable(props) {
  const [rows, setRows] = React.useState(mockData);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [dataInventaire, setDataInventaire] = React.useState('');
  const [srcImageInv, setSrcImageInv] = React.useState('');
  const [spinner, setSpinner] = React.useState(false);
  const [openAlertErrorRequest, setOpenAlertErrorRequest] = React.useState(false);
  const [alertErrorRequest, setAlertErrorRequest] = React.useState(null);

  const handleCloseAlertErrorRequest = () => {
    setOpenAlertErrorRequest(false);
    setAlertErrorRequest(null);
  };

  const showImage = (_event, row) => {
    let blob;
    if (
      row.Photo.toLowerCase().includes('.png') ||
      row.Photo.toLowerCase().includes('.jpg') ||
      row.Photo.toLowerCase().includes('.jpeg')
    ) {
      blob = b64toBlob(row.Photo, 'image/png;base64');
    } else if (row.Photo.toLowerCase().includes('.pdf')) {
      blob = b64toBlob(row.Photo, 'application/pdf');
    }
    if (blob) {
      const url = URL.createObjectURL(blob);
      window.open(url);
    }
  };

  const openDialogInventaire = (_event, row) => {
    setDataInventaire(row);
    setOpenDialog(true);
    if (row.Photo?.preview) {
      setSrcImageInv(<PreviewStyleDialog src={row.Photo.preview} alt="Preview" />);
    } else {
      setSrcImageInv(
        <PreviewStyleDialog
          src={`data:image/*;base64,${row.Photo}`}
          alt="Preview"
        />
      );
    }
  };

  const addToInventaire = (check) => {
    if (check === 'cancel') {
      setOpenDialog(false);
      setDataInventaire('');
      setSrcImageInv('');
    } else {
      setSpinner(true);

      const formData = new FormData();
      if (dataInventaire.Photo?.preview) {
        formData.append('files', dataInventaire.Photo);
      } else {
        formData.append('imgBase64', dataInventaire.Photo);
      }

      formData.append('nameFiles', dataInventaire.Name);
      formData.append('zone', dataInventaire.Zone);
      formData.append('specialite', dataInventaire.Activite);
      formData.append('objet', dataInventaire.Objet);
      formData.append('NumeroMagasin', props.NumMag);
      formData.append('Enseigne', props.Enseigne);
      formData.append('Attribut', 'Inventaires');

      axios.defaults.withCredentials = true;
      axios
        .post('/legacy/addInventaires', formData)
        .then(() => {
          setOpenDialog(false);
          setDataInventaire('');
          setSrcImageInv('');
          setSpinner(false);
        })
        .catch(() => {
          setOpenAlertErrorRequest(true);
          setAlertErrorRequest(
            <Alert onClose={handleCloseAlertErrorRequest} severity="error">
              Erreur ajout photo dans l'inventaire!
            </Alert>
          );
          setSpinner(false);
        });
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Snackbar
        open={openAlertErrorRequest}
        autoHideDuration={6000}
        onClose={handleCloseAlertErrorRequest}
      >
        {alertErrorRequest}
      </Snackbar>

      <Dialog
        open={openDialog}
        onClose={() => addToInventaire('cancel')}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Ajouter à l'inventaire</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Voulez-vous vraiment ajouter cette photo à l'inventaire du magasin ?
          </DialogContentText>
          {spinner && <Spinner />}
          {!spinner && (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              {srcImageInv}
            </Box>
          )}
        </DialogContent>
        {!spinner && (
          <DialogActions>
            <Button onClick={() => addToInventaire('cancel')} color="primary">
              Annuler
            </Button>
            <Button onClick={() => addToInventaire('save')} color="primary">
              Ajouter
            </Button>
          </DialogActions>
        )}
      </Dialog>

      <StyledTableContainer component={Paper}>
        <Table size="small" stickyHeader aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Zone</TableCell>
              <TableCell>Activite</TableCell>
              <TableCell>Objet</TableCell>
              <TableCell>Photo</TableCell>
              <TableCell>Marque</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Reference</TableCell>
              <TableCell>Commentaire</TableCell>
              <TableCell>File ID</TableCell>
              <TableCell>Quantite</TableCell>
              <TableCell>Etats</TableCell>
              <TableCell>Conformité</TableCell>
              <TableCell>Criticité</TableCell>
              <TableCell>Maintenabilité</TableCell>
              {props.removeFile && (
                <>
                  <TableCell align="center">Ajouter</TableCell>
                  <TableCell align="center">Supprimer</TableCell>
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.ID}>
                <TableCell>{row.ID}</TableCell>
                <TableCell>{row.Name}</TableCell>
                <TableCell>{row.Zone}</TableCell>
                <TableCell>{row.Activite}</TableCell>
                <TableCell>{row.Objet}</TableCell>
                <TableCell>
                  <IconImage onClick={(event) => showImage(event, row)} />
                </TableCell>
                <TableCell>{row.Marque}</TableCell>
                <TableCell>{row.Type}</TableCell>
                <TableCell>{row.Reference}</TableCell>
                <TableCell>{row.Commentaire}</TableCell>
                <TableCell>{row.File_id}</TableCell>
                <TableCell>{row.Quantite}</TableCell>
                <TableCell>{row.Etats}</TableCell>
                <TableCell>{row.Conformité}</TableCell>
                <TableCell>{row.Criticité}</TableCell>
                <TableCell>{row.Maintenabilité}</TableCell>

                {props.removeFile && (
                  <>
                    <TableCell align="center">
                      <IconPublish onClick={(event) => openDialogInventaire(event, row)} />
                    </TableCell>
                    <TableCell align="center">
                      <IconDelete onClick={(event) => props.removeFile(event, row)} />
                    </TableCell>
                  </>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </Box>
  );
}
