import axios from 'axios'
import {
    GET_PILOTE, GET_RELANCE_BY_USER, GET_USERS, POST_USER, PUT_USER, GET_USER_ENTREPRISE_BY_ID, GET_USERS_ACTIVE, PUT_USER_IS_ACTIVE
} from "./CONSTANTS";

export const GetPilote = () => {
    return new Promise((resolve, reject) => {
        try {
            axios.defaults.withCredentials = true
            axios.get(GET_PILOTE())
                .then((res) => {
                    resolve(res.data);
                }).catch((err) => {
                    console.log("GetPilote > axios err=", err);
                    reject("Error in GetPilote axios!");
                });
        } catch (error) {
            console.error("in userServices > GetPilote, Err===", error);
        }
    });
};

export const GetUser = (userId) => {
    return new Promise((resolve, reject) => {
        try {
            axios.defaults.withCredentials = true
            axios.get(GET_PILOTE(userId))
                .then((res) => {
                    resolve(res.data);
                }).catch((err) => {
                    console.log("GetPilote > axios err=", err);
                    reject("Error in GetPilote axios!");
                });
        } catch (error) {
            console.error("in userServices > GetPilote, Err===", error);
        }
    });
};

export const GetAllUsers = () => {
    return new Promise((resolve, reject) => {
        try {
            axios.defaults.withCredentials = true
            axios.get(GET_USERS())
                .then((res) => {
                    resolve(res.data);
                }).catch((err) => {
                    console.log("GetAllUseres > axios err=", err);
                    reject("Error in GetAllUser axios!");
                });
        } catch (error) {
            console.error("in userServices > GetAllUsers, Err===", error);
        }
    });
};

export const GetAllUsersActive = (entreprise) => {
    return new Promise((resolve, reject) => {
        try {
            axios.defaults.withCredentials = true
            axios.get(GET_USERS_ACTIVE(entreprise))
                .then((res) => {
                    resolve(res.data);
                }).catch((err) => {
                    console.log("GetAllUseres > axios err=", err);
                    reject("Error in GetAllUser axios!");
                });
        } catch (error) {
            console.error("in userServices > GetAllUsers, Err===", error);
        }
    });
}

export const GetRelanceByUser = () => {
    return new Promise((resolve, reject) => {
        try {
            axios.defaults.withCredentials = true
            axios.get(GET_RELANCE_BY_USER())
                .then((res) => {
                    resolve(res.data);
                }).catch((err) => {
                    console.log("GetRelanceByUser > axios err=", err);
                    reject("Error in GetRelanceByUser axios!");
                });
        } catch (error) {
            console.error("in userServices > GetRelanceByUser, Err===", error);
        }
    });
};

export const CreateUser = (form) => {
    return new Promise((resolve, reject) => {
        try {
            axios.defaults.withCredentials = true
            axios.post(POST_USER(), form)
                .then((res) => {
                    resolve(res.data);
                }).catch((err) => {
                    console.log("creation d'un nouvelle utilisateur > axios err=", err);
                    reject("Error in CreateUser axios!");
                });
        } catch (error) {
            console.log(error)
            console.error("in userservice > CreateUser, Err===", error);
        }
    });
}

export const EditUser = (form) => {
    return new Promise((resolve, reject) => {
        try {
            axios.defaults.withCredentials = true
            axios.put(PUT_USER(), form)
                .then((res) => {
                    resolve(res.data);
                }).catch((err) => {
                    console.log("modification d'un  utilisateur > axios err=", err);
                    reject("Error in EditUser axios!");
                });
        } catch (error) {
            console.log(error)
            console.error("in userservice > EditUser, Err===", error);
        }
    });
}

export const ChangeUserState = (form) => {
    return new Promise((resolve, reject) => {
        try {
            axios.defaults.withCredentials = true
            axios.put(PUT_USER_IS_ACTIVE(), form)
                .then((res) => {
                    resolve(res.data);
                }).catch((err) => {
                    console.log("modification d'un  utilisateur > axios err=", err);
                    reject("Error in ChangeUserState axios!");
                });
        } catch (error) {
            console.log(error)
            console.error("in userservice > ChangeUserState, Err===", error);
        }
    });
}


export const GetUserEntreprise = (userId) => {
    return new Promise((resolve, reject) => {
        try {
            axios.defaults.withCredentials = true
            axios.get(GET_USER_ENTREPRISE_BY_ID(userId))
                .then((res) => {
                    resolve(res.data);
                }).catch((err) => {
                    console.log(" GetUserEntreprise > axios err=", err);
                    reject("Error GetUserEntreprise axios!");
                });
        } catch (error) {
            console.error("in interventionServices > GetUserEntreprise, Err===", error);
        }
    });
};
