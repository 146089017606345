import React, { useReducer, useState, useContext } from 'react';
import axios from 'axios';
import {
    Stack,
    IconButton,
    Popover,
    ListItemText,
    ListItemButton,
    ListItem,
    Skeleton,
    Typography,
    List,
    Collapse,
} from '@mui/material';
import { styled } from '@mui/material/styles';
//date
import { dateConfig } from 'config'
import { formatDate } from 'utils/date';
//icone
import NotificationsIcon from '@mui/icons-material/Notifications';
import CloseIcon from '@mui/icons-material/Close';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
//
import { findIndex } from "lodash"
import Label from "components/UI/Label";
import RelanceContext from 'contexts/RelanceContext';
import { GetRelanceByUser } from 'services';

// ----------------------------------------------------------------------

const ListItemButtonStyled = styled(ListItemButton)(({ theme, read }) => {
    return {
        ...read && {
            background: 'rgb(215, 229, 249, 0.5)'
        }
    };
});

// ----------------------------------------------------------------------

const openListReducer = (state, action) => {
    if (action.type === 'OPEN_LIST_DAY')
        return { isOpenDay: !state.isOpenDay }
    if (action.type === 'OPEN_LIST_YESTERDAY')
        return { isOpenYesterday: !state.isOpenYesterday }
    if (action.type === 'OPEN_LIST_BEFORE_YESTERDAY')
        return { isOpenBeforeYesterday: !state.isOpenBeforeYesterday }
    if (action.type === 'OPEN_LIST_REST')
        return { isOpenRest: !state.isOpenRest }
}

export default function NavbarRelance({ relanceInfo, onLoading, sortTabRelance }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [openList, dispacheOpenList] = useReducer(openListReducer, {
        isOpenDay: true,
        isOpenYesterday: false,
        isOpenBeforeYesterday: false,
        isOpenRest: false,
    });
    const ctx = useContext(RelanceContext)

    const handleClickDayList = (triggerList) => {
        if (triggerList === "OPEN_LIST_DAY")
            dispacheOpenList({ type: triggerList, value: true });
        if (triggerList === "OPEN_LIST_YESTERDAY")
            dispacheOpenList({ type: triggerList, value: true });
        if (triggerList === "OPEN_LIST_BEFORE_YESTERDAY")
            dispacheOpenList({ type: triggerList, value: true });
        if (triggerList === "OPEN_LIST_REST")
            dispacheOpenList({ type: triggerList, value: true });
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onClickTreatRelance = (relance, key) => {
        axios.defaults.withCredentials = true
        axios.put('/legacy/putMention', { ID: relance.ID, relance_treat: true })
            .then(response => {
                GetRelanceByUser().then(response => {
                    sortTabRelance(response.comments)
                    ctx.onTreatRelance()
                }).catch(error => {
                    console.log(error)
                });
            }).catch(error => {
                console.log(error)
            });
    }

    const onClickChangeReadStatus = (relance, key) => {
        axios.defaults.withCredentials = true
        axios.put('/legacy/putMention', { ID: relance.ID, read: true })
            .then(response => {
                const indexToChange = findIndex(relanceInfo[key], { ID: relance.ID })
                relanceInfo[key][indexToChange] = {
                    ID: relance.ID, IDMastore: relance.IDMastore,
                    author: relance.author, color: relance.color, commentaire: relance.commentaire, read: true,
                    recipient_id: relance.recipient_id, time: relance.time,
                    user_id: relance.user_id
                }
                handleClose()
            }).catch(error => {
                console.log(error)
            });
    }

    const displayList = (tabRelance, key) => {
        if (tabRelance.length > 0) {
            return (
                <React.Fragment>
                    {tabRelance.map((item, index) => (
                        <ListItem
                            key={index}
                            sx={{ px: 1, py: 0.5 }}
                            secondaryAction={
                                <IconButton sx={{ mr: 1 }} edge="end" aria-label="delete" onClick={() => onClickTreatRelance(item, key)}>
                                    <CloseIcon />
                                </IconButton>
                            }
                            disablePadding
                        >
                            <ListItemButtonStyled
                                read={item.read}
                                component={'a'} href={"/intervention?IDMastore=" + item.IDMastore}
                                onClick={() => onClickChangeReadStatus(item, key)}
                                target="_blank"
                            >
                                <Stack spacing={0.5} direction={"column"}>
                                    <ListItemText
                                        secondary={`${item.author} - ${formatDate(item.time, dateConfig.format5).toLowerCase()}`}
                                    />
                                    <Typography variant="body1" display="block" gutterBottom>
                                        {item.commentaire}
                                    </Typography>
                                </Stack>
                            </ListItemButtonStyled >
                        </ListItem >

                    ))
                    }
                </React.Fragment >
            );
        } else {
            return (
                <Typography sx={{ textAlign: "center", fontSize: "16px", padding: 1 }}> Aucune relance</Typography>
            )
        }
    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div>
            <IconButton aria-label="button" onClick={handleClick}>
                <NotificationsIcon />
            </IconButton>
            {onLoading ?
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    disableScrollLock={true}
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'center',
                        horizontal: 'left',
                    }}
                >
                    <List sx={{ width: "450px", padding: 0 }} >
                        <ListItemButton sx={{ backgroundColor: "rgba(145, 158, 171, 0.19)" }}
                            onClick={() => handleClickDayList("OPEN_LIST_DAY")}>
                            <ListItemText primary="Aujourd'hui" sx={{ m: 1 }} />
                            <Label sx={{ mr: 2 }} color={"info"}> {relanceInfo.TabToday.length} </Label>
                            {openList.isOpenDay ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={openList.isOpenDay} timeout="auto" unmountOnExit>
                            <List disablePadding>
                                {displayList(relanceInfo.TabToday, "TabToday")}
                            </List>
                        </Collapse>
                        <ListItemButton sx={{ backgroundColor: "rgba(145, 158, 171, 0.19)" }} onClick={() => handleClickDayList("OPEN_LIST_YESTERDAY")}>
                            <ListItemText sx={{ m: 1 }} primary="Hier" />
                            <Label sx={{ mr: 2 }} color={"error"}> {relanceInfo.TabYesterday.length} </Label>
                            {openList.isOpenYesterday ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={openList.isOpenYesterday} timeout="auto" unmountOnExit>
                            <List disablePadding>
                                {displayList(relanceInfo.TabYesterday, "TabYesterday")}
                            </List>
                        </Collapse>
                        <ListItemButton sx={{ backgroundColor: "rgba(145, 158, 171, 0.19)" }} onClick={() => handleClickDayList("OPEN_LIST_BEFORE_YESTERDAY")}>
                            <ListItemText sx={{ m: 1 }} primary="Avant-hier" />
                            <Label sx={{ mr: 2 }} color={"error"}> {relanceInfo.TabBeforeYesterday.length} </Label>
                            {openList.isOpenBeforeYesterday ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={openList.isOpenBeforeYesterday} timeout="auto" unmountOnExit>
                            <List disablePadding>
                                {displayList(relanceInfo.TabBeforeYesterday, "TabBeforeYesterday")}
                            </List>
                        </Collapse>
                        <ListItemButton sx={{ backgroundColor: "rgba(145, 158, 171, 0.19)" }} onClick={() => handleClickDayList("OPEN_LIST_REST")}>
                            <ListItemText sx={{ m: 1 }} primary="Plus de 3 jours" />
                            <Label sx={{ mr: 2 }} color={"error"}> {relanceInfo.TabRest.length} </Label>
                            {openList.isOpenRest ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton >
                        <Collapse in={openList.isOpenRest} timeout="auto" unmountOnExit>
                            <List disablePadding>
                                {displayList(relanceInfo.TabRest, "TabRest")}
                            </List>
                        </Collapse>
                    </List >
                </Popover >
                : <Skeleton variant="rectangular" width={0} height={0} />
            }
        </div >
    );
}