import React, { useEffect } from 'react';
// material
import {
    Card,
    Grid,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    DialogTitle,
    Button,
    InputLabel,
    TextField,
    Select,
    MenuItem,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import FolderIcon from '@mui/icons-material/Folder';
import CloudIcon from '@mui/icons-material/Cloud';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import MailIcon from '@mui/icons-material/Mail';
// Components
import Specialite from 'components/UI/Specialite/Specialite'
import CloseButton from 'components/UI/CloseButton';
import ModalButton from 'components/UI/ModalButton';
import axios from 'axios';
import { InformationInterventionRights } from 'config/rights/interventions/infoInterventionRights';


// ----------------------------------------------------------------------

const MailDiv = styled('a')(({ theme }) => ({
    textDecoration: "none",
    color: theme.palette.primary.main,
    whiteSpace: "nowrap",
}));

// ----------------------------------------------------------------------

const options = { weekday: 'long', month: 'short', day: 'numeric', year: "numeric" };

export default function GeneralInformation(props) {
    const { infoIntervention, user, fileInventaireLength, openInventaireModal, openModalHistorique, updateInfoIntervention, contact_client } = props;
    const [open, setOpen] = React.useState(false)
    const [pilotes, setPilotes] = React.useState([])
    const role = InformationInterventionRights(user);

    useEffect(() => {
        axios.get('/legacy/pilote').then(response => {
            setPilotes(response.data.pilotes)
        })
    }, [])

    const handleDialog = () => {
        setOpen(!open)
    }

    return (
        <Card variant="outlined" style={{ height: 'auto' }}>
            <Grid container>
                <Grid container item xs={4.5} sx={{ p: 1, position: "relative" }} spacing={0.5}>
                    <Grid item lg={6} md2={6} xs={6}>
                        <Typography variant="h6" style={{ fontWeight: "bold" }} color="primary">ID Mastore :</Typography>
                        <Typography variant="body2">{infoIntervention.id_mastore}</Typography>
                    </Grid>
                    <Grid item lg={6} md2={6} xs={6}>
                        <Typography variant="h6" style={{ fontWeight: "bold" }} color="primary">DI Client :</Typography>
                        <Typography variant="body2">{infoIntervention.id_client}</Typography>
                    </Grid>

                    <Grid item lg={6} xs={12}>
                        <Typography variant="h6" style={{ fontWeight: "bold" }} color="primary">Spécialité(s) :</Typography>
                        <Typography variant="body2">{infoIntervention.specialite}</Typography>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Typography variant="h6" style={{ fontWeight: "bold" }} color="primary">Demande du :</Typography>
                        <Typography variant="body2" style={{ textTransform: "capitalize" }}>{new Date(infoIntervention.date_demande).toLocaleDateString("fr-FR", options)}</Typography>
                    </Grid>
                    <IconButton aria-label="delete" onClick={handleDialog} size="small" sx={{ position: "absolute", right: '5px', top: '7px', }}>
                        <SettingsIcon />
                    </IconButton>
                </Grid>
                <Divider orientation="vertical" flexItem style={{ marginRight: "-2px" }} />
                <Grid container item xs={7.5} sx={{ position: "relative" }} spacing={0.5}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                        <div style={{ flex: '4', padding: '1rem' }}>
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
                                <LocationOnIcon color="primary" />
                                <div style={{ marginLeft: '0.5rem' }}>
                                    <Typography variant="body2">{infoIntervention.adresse}</Typography>
                                    <Typography variant="body2">{infoIntervention.complement_adresse}</Typography>
                                    <Typography variant="body2">{infoIntervention.implantation}</Typography>
                                </div>
                            </div>

                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
                                <PhoneIcon color="primary" />
                                <Typography variant="body2" style={{ marginLeft: '0.5rem' }}>{infoIntervention.telephone}</Typography>
                            </div>

                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <MailIcon color="primary" />
                                <MailDiv href={`mailto:${infoIntervention.mail}`} style={{ marginLeft: '0.5rem' }}>{infoIntervention.mail}</MailDiv>
                            </div>
                        </div>

                        <div style={{ flex: '1', padding: '1rem', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', gap: '1rem' }}>
                            <ModalButton
                                disabled
                                name="Inventaire(s)"
                                filled={fileInventaireLength > 0}
                                clickHandler={openInventaireModal}
                                icon={<CloudIcon />}
                            />
                            <ModalButton
                                name="Historique"
                                filled={true}
                                clickHandler={openModalHistorique}
                                icon={<FolderIcon />}
                            />
                        </div>
                    </div>

                </Grid>

            </Grid>

            <Dialog
                open={open}
                onClose={handleDialog}
                fullWidth={false}
            >
                <CloseButton close={handleDialog} />
                <DialogTitle sx={{ textAlign: 'center' }}>
                    Informations Générales
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <TextField
                                label="ID Client"
                                variant="outlined"
                                margin='dense'
                                size='small'
                                value={infoIntervention.id_client}
                                InputLabelProps={{ shrink: true }}
                                onChange={(event) => updateInfoIntervention(event.target.value, 'id_client')}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth margin="dense">
                                <InputLabel>Spécialité</InputLabel>
                                <Specialite value={infoIntervention.specialite} changeSpecialite={(event) => updateInfoIntervention(event.target.value, 'specialite')} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel>Statut</InputLabel>
                                <Select
                                    value={infoIntervention.status}
                                    onChange={(event) => updateInfoIntervention(event.target.value, 'status')}
                                    variant="outlined"
                                    label="Statut"
                                    size="small"
                                    margin="dense"
                                >
                                    <MenuItem value="EN COURS">En cours</MenuItem>
                                    <MenuItem value="Validée" disabled={!(role <= 2)}>Validée</MenuItem>
                                    <MenuItem value="FACTUREE" disabled={!(role < 2)}>Facturée</MenuItem>
                                    <MenuItem value="EN ATTENTE" disabled={!(role < 2)}>En attente</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel>Contact client</InputLabel>
                                <Select
                                    value={infoIntervention.contact_client === "" ? "select" : infoIntervention.contact_client}
                                    onChange={(event) => updateInfoIntervention(event.target.value, 'contact_client')}
                                    variant="outlined"
                                    label="Contact Client"
                                    size="small"
                                    margin="dense"
                                    InputLabelProps={{ shrink: true }}
                                >
                                    <MenuItem value={'select'}>{`Sélectionnez un contact`}</MenuItem>
                                    {contact_client.map(contact => (
                                        <MenuItem key={contact.ID} value={`${contact.prenom} ${contact.nom}`}>{`${contact.prenom} ${contact.nom}`}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel shrink variant="outlined">Contact Mastore</InputLabel>
                                <Select
                                    onChange={(event) => updateInfoIntervention(event.target.value, 'pilote')}
                                    label="Contact Mastore"
                                    size="small"
                                    value={infoIntervention.pilote}
                                >
                                    {pilotes.map(item => (
                                        <MenuItem key={item.ID} value={item.ID}> {item.firstname + ' ' + item.lastname} </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel>Type d'intervention</InputLabel>
                                <Select
                                    value={infoIntervention.type}
                                    onChange={(event) => updateInfoIntervention(event.target.value, 'type')}
                                    variant="outlined"
                                    label="Type d'intervention"
                                    size="small"
                                    margin="dense"
                                >
                                    <MenuItem value="corrective">Corrective</MenuItem>
                                    <MenuItem value="préventive" disabled={!(role <= 3)}>Préventive</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth disabled={infoIntervention.urgency === "standby"}>
                                <InputLabel>Niveau de criticité</InputLabel>
                                <Select
                                    value={infoIntervention.urgency}
                                    onChange={(event) => updateInfoIntervention(event.target.value, 'urgency')}
                                    variant="outlined"
                                    label="Niveau de criticité"
                                    size="small"
                                    margin="dense"
                                    displayEmpty // Permet d'afficher la valeur actuelle même si le composant est désactivé
                                >
                                    <MenuItem value="non-urgent">Non urgent</MenuItem>
                                    <MenuItem value="standard">Standard</MenuItem>
                                    <MenuItem value="urgent">Urgent</MenuItem>
                                    <MenuItem value="critique">Critique</MenuItem>
                                    <MenuItem value="standby">Standby</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialog} autoFocus>
                        Validé
                    </Button>
                </DialogActions>
            </Dialog>
        </Card >
    )
}