import React from 'react';
// mui
import {
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    Button,
    Paper,
    IconButton,
    Box,
} from '@mui/material';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import { styled } from '@mui/material/styles';
//components
import SelectMonth from 'components/UI/SelectMonth';
import TableDateIntervention from './TableDateIntervention'
import TableMobileDatePicker from 'components/UI/TableInput/TableMobileDatePicker'
import MenuAction from './MenuAction'
import CustomSelect from "./CustomSelect"
import IntervenantPopover from './IntervenantPopover'
import { sumBy } from 'lodash'
import moment from 'moment';

// ----------------------------------------------------------------------

const StyledTableCellHeader = styled(TableCell)(({ theme }) => ({
    padding: "5px 10px",
    textAlign: "center",
}));

const StyledTableCell = styled(TableCell)(() => ({
    padding: "5px 5px",
}));

const StyledTableContainer = styled(TableContainer)(() => ({
    maxHeight: "400px",
    minHeight: "70px",
    borderTopLeftRadius: "3px",
    borderTopRigthRadius: "3px",
}));

const StyledTableRow = styled(TableRow)(({ date_intervention }) => {
    return {
        borderColor: 'rgb(213, 213, 213)',
        ...(date_intervention.length > 0 && {
            '& > *': {
                borderBottom: 'unset !important'
            }
        }),
    }
});

// ----------------------------------------------------------------------


export default function TableAction(props) {
    const {
        user,
        actions,
        infoIntervention,
        handleSelectIntervenant,
        newDateIntervention,
        modifyDateIntervention,
        deleteDateIntervention,
        newAction,
        modifyAction,
        deleteAction,
        openActionFacture,
        openActionDevis,
        triggerDateMax,
        hasSentDevis,
    } = props;

    return (
        <Box sx={{ flex: "1 1 auto" }}>
            <StyledTableContainer component={Paper}>
                <Table stickyHeader size="small">
                    {/* // HEADER \\ */}
                    <TableHead>
                        <TableRow>
                            <StyledTableCellHeader>Contact</StyledTableCellHeader>
                            <StyledTableCellHeader>Spécialité</StyledTableCellHeader>
                            <StyledTableCellHeader>Ville</StyledTableCellHeader>
                            <StyledTableCellHeader>État</StyledTableCellHeader>
                            <StyledTableCellHeader>Action</StyledTableCellHeader>
                            <StyledTableCellHeader>Total (TTC)</StyledTableCellHeader>
                            <StyledTableCellHeader align="right"></StyledTableCellHeader>
                            <StyledTableCellHeader align="right"></StyledTableCellHeader>
                        </TableRow>
                    </TableHead>
                    {/* // ROWS \\ */}
                    <TableBody>
                        {actions.map((action, index) => (
                            <React.Fragment key={index}>
                                <StyledTableRow date_intervention={action.date_intervention}>
                                    {
                                        action.intervenant_id === -1 ?
                                            <StyledTableCell align="center">
                                                <Button variant="outlined" onClick={() => handleSelectIntervenant(action)} size="small">
                                                    Sélectionner
                                                </Button>
                                            </StyledTableCell> :
                                            <StyledTableCell align="left">
                                                <IntervenantPopover intervenant={action.intervenant} />
                                            </StyledTableCell>
                                    }
                                    <StyledTableCell align="center">{action.intervenant.specialite !== "" ? action.intervenant.specialite : infoIntervention.Specialite}</StyledTableCell>
                                    <StyledTableCell align="center">{action.intervenant.ville}</StyledTableCell>
                                    <StyledTableCell>
                                        <CustomSelect
                                            action={action}
                                            onClick={(value, old_value) => { triggerDateMax('etat', value, old_value, action.ID, true) }}
                                            infoIntervention={infoIntervention}
                                            hasSentDevis={hasSentDevis}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        {
                                            infoIntervention.type === "préventive" ?
                                                <SelectMonth
                                                    onChange={(value) => modifyAction('date_action', value, action.ID, true)}
                                                    action={action}
                                                /> :
                                                action.etat === "A SUIVRE" ? null :
                                                    <TableMobileDatePicker
                                                        inputFormat={infoIntervention.type === "préventive" ? "MMMM" : "D MMM"}
                                                        value={moment(action.date_action)}
                                                        onChange={(value) => modifyAction('date_action', value, action.ID, true)}
                                                        disabled={infoIntervention.status === "A FACTURER" || infoIntervention.status === "FACTUREE" || action.etat === "A CLORE" || action.etat === "CLOTUREE" || action.etat === "A SUIVRE" ? true : false}
                                                    />
                                        }
                                    </StyledTableCell>
                                    <StyledTableCell align="center">{action.total.toFixed(2)} €</StyledTableCell>
                                    <StyledTableCell>
                                        <IconButton
                                            aria-label="add"
                                            onClick={() => newDateIntervention(action)}
                                            disabled={action.etat === "A CLORE" || action.etat === "CLOTUREE" || action.intervenant_id === -1 ? true : false}
                                            color='primary'
                                        >
                                            <MoreTimeIcon />
                                        </IconButton>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <MenuAction
                                            action={action}
                                            deleteAction={deleteAction}
                                            handleSelectIntervenant={handleSelectIntervenant}
                                            openActionFacture={openActionFacture}
                                            openActionDevis={openActionDevis}
                                        />
                                    </StyledTableCell>
                                </StyledTableRow>
                                {(action.date_intervention.length > 0 && action.etat === "A CLORE") || action.etat === "CLOTUREE" || action.etat === "A SUIVRE" || action.etat === "ATTENTE CLIENT" ?
                                    <TableDateIntervention
                                        user={user}
                                        action={action}
                                        infoIntervention={infoIntervention}
                                        //Date Interventions Handlers
                                        newDateIntervention={newDateIntervention}
                                        modifyDateIntervention={modifyDateIntervention}
                                        deleteDateIntervention={deleteDateIntervention}
                                        disabled={action.etat === "A CLORE" || action.etat === "CLOTUREE" || action.etat === "ATTENTE CLIENT" ? true : false}
                                    /> : null
                                }
                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
            </StyledTableContainer>

            {/* // FOOTER \\ */}
            <TableContainer component={Paper} sx={{ maxHeight: 430, borderTopLeftRadius: "0px", borderTopRightRadius: "0px" }} >
                <Table size="small">
                    <TableBody>
                        <TableRow style={{ backgroundColor: "#fff" }}>
                            <TableCell>
                                <Button variant="outlined" size="small" onClick={newAction}>
                                    Nouvelle action
                                </Button>
                            </TableCell>
                            <TableCell />
                            <TableCell />
                            <TableCell />
                            <TableCell />
                            <TableCell />
                            <TableCell align="right" style={{ fontWeight: "bold", color: "rgb(49, 102, 144)" }}>Total : {sumBy(actions, function (f) { return f.total }).toFixed(2)} €</TableCell>
                            <TableCell />
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}