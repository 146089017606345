import React, { useState } from 'react';
// material
import {
    Button,
    Menu,
    MenuItem,
    Divider,
} from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { styled, alpha } from '@mui/material/styles';
// components
import { STATUTS_COLOR } from 'config/index'

// ----------------------------------------------------------------------

const ButtonStyled = styled(Button)(({ value }) => {
    return {
        backgroundColor: STATUTS_COLOR[value],
        width: "100px",
        height: "25px",
        borderColor: STATUTS_COLOR[value],
        color: 'white',
        '&:hover': {
            backgroundColor: alpha(STATUTS_COLOR[value], 0.8),
            borderColor: STATUTS_COLOR[value],
        },
    };
});

const MenuItemStyled = styled(MenuItem)(() => ({
    padding: "5px 10px",
}));

const MenuStyled = styled(Menu)(() => ({
    "& .MuiList-root": {
        width: "100px",
    }
}));

const TruncateStatus = styled("div")(() => ({
    width: "80px",
    lineHeight: '15px',
    color: "white",
    fontWeight: "bold",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
}));

// ----------------------------------------------------------------------

export default function CustomSelect({ action, infoIntervention, onClick, hasSentDevis }) {
    const [customSelect, setCustomSelect] = useState(null);
    const [attenteMenu, setAttenteMenu] = useState(null);

    const handleClick = (event) => {
        setCustomSelect(event.currentTarget);
    };

    const handleClose = () => {
        setCustomSelect(null);
    };

    const handleChangeState = (value) => {
        setCustomSelect(null)
        setAttenteMenu(null)
        onClick(value, action.etat)
    }

    return (
        <React.Fragment>
            <ButtonStyled
                aria-controls="customSelect"
                variant="outlined"
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
                value={action.etat}
            >
                <TruncateStatus>
                    {action.etat}
                </TruncateStatus>
            </ButtonStyled>
            <MenuStyled
                getcontentanchorel={null}
                id="customSelect"
                anchorEl={customSelect}
                keepMounted
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={Boolean(customSelect)}
                onClose={handleClose}
            >
                {
                    action.etat === "A CHIFFRER" || action.etat === "ATTENTE INTERVENANT" || action.etat === "ATTENTE VALIDATION CLIENT" || action.etat === "ARCHIVEE" || action.etat === "A EXPERTISER" ?
                        <MenuItemStyled onClick={() => handleChangeState("A PLANIFIER")}>A planifier</MenuItemStyled> : null
                }
                {
                    action.etat === "A PLANIFIER" || action.etat === "ATTENTE INTERVENANT" || action.etat === "ATTENTE VALIDATION CLIENT" || action.etat === "A EXPERTISER" ?
                        <MenuItemStyled onClick={() => handleChangeState("A CHIFFRER")}>A chiffrer</MenuItemStyled> : null
                }
                {
                    (infoIntervention.type !== 'préventive' && action.etat === "A PLANIFIER") || action.etat === "ATTENTE INTERVENANT" || action.etat === "ATTENTE VALIDATION CLIENT" || action.etat === "A CHIFFRER" ?
                        <MenuItemStyled onClick={() => handleChangeState("A EXPERTISER")}>A expertiser</MenuItemStyled> : null
                }
                {
                    action.etat === "A SUIVRE" || action.etat === "CLOTUREE" ?
                        <MenuItemStyled onClick={() => handleChangeState("A CLORE")}>A clore</MenuItemStyled> : null
                }
                {
                    action.etat === "CLOTUREE" ?
                        <MenuItemStyled onClick={() => handleChangeState("ATTENTE CLIENT")}>Attente Client</MenuItemStyled> : null
                }
                {
                    action.etat === "A CLORE" || action.etat === "A FACTURER" || action.etat === "ATTENTE CLIENT" ?
                        <MenuItemStyled onClick={() => handleChangeState("CLOTUREE")}>Cloturée</MenuItemStyled> : null
                }
                {
                    infoIntervention.type !== 'préventive' && action.etat !== "A SUIVRE" && action.etat !== "CLOTUREE" && action.etat !== "ARCHIVEE" && action.etat !== "A FACTURER" && action.etat !== "FACTUREE" ?
                        <Divider variant="fullWidth" style={{ margin: "5px 10px" }} /> : null
                }
                {
                    action.etat === "A CLORE" ?
                        <MenuItemStyled onClick={() => handleChangeState("A SUIVRE")}>A suivre</MenuItemStyled> : null
                }
                {/* MULTI ATTENTE MENU */}
                {
                    action.etat === "A PLANIFIER" || action.etat === "ATTENTE INTERVENANT" || action.etat === "ATTENTE VALIDATION CLIENT" || action.etat === "A CHIFFRER" || action.etat === "A EXPERTISER" ?
                        <MenuItemStyled aria-controls="attenteMenu" onClick={(event) => setAttenteMenu(event.currentTarget)}>
                            En attente
                            <div style={{ margin: "auto 0px auto auto", display: 'flex' }}>
                                <ArrowForwardIosIcon style={{ fontSize: "10px !important" }} />
                            </div>
                        </MenuItemStyled> : null
                }
                <Menu
                    id="attenteMenu"
                    anchorEl={attenteMenu}
                    getcontentanchorel={null}
                    keepMounted
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    open={Boolean(attenteMenu)}
                    onClose={(event) => setAttenteMenu(null)}
                >
                    <MenuItemStyled onClick={() => handleChangeState("ATTENTE INTERVENANT")}>Intervenant</MenuItemStyled>
                    {infoIntervention.type !== 'préventive' ? <MenuItemStyled onClick={() => handleChangeState("ATTENTE VALIDATION CLIENT")} disabled={!hasSentDevis} >Validation client</MenuItemStyled> : null}
                </Menu>

                {
                    action.etat === "A CHIFFRER" || action.etat === "ATTENTE INTERVENANT" || action.etat === "ATTENTE VALIDATION CLIENT" || action.etat === "A EXPERTISER" ?
                        <Divider variant="fullWidth" style={{ margin: "5px 10px" }} /> : null
                }
                {
                    <MenuItemStyled onClick={() => handleChangeState("ARCHIVEE")}>Archivée</MenuItemStyled>
                }
            </MenuStyled>
        </React.Fragment>
    );
}