import React, { Component } from 'react';
import { Stack, Typography } from '@mui/material';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Alert from '@mui/material/Alert';
import { SaveButton, DivButton, CreateButton, IconCreate } from './DemandeDevisStyle';
import TableEmailIntervenant from '../TableEmailIntervenant/TableEmailIntervenant';

import Modal from 'components/UI/Modal';
import Header from 'components/UI/Header/Header';
import CreateDevis from './CreateDevis/CreateDevis';
import Mailbox from 'views/Mail/Mail';

class DemandeDevis extends Component {
    constructor(props) {
        super(props);
        this.state = {
            waitFiles: [],
            fileDemandeDevis: this.props.fileDemandeDevisIntervenant,
            removeDemandeDevis: [],
            sendMailBool: false,
            Attachment: [],
            alert: null,
            openCreateDemandeDevis: false,
            openEmail: false,
            hasChanges: false,
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.fileDemandeDevisIntervenant !== this.props.fileDemandeDevisIntervenant) {
            this.setState({
                fileDemandeDevis: this.props.fileDemandeDevisIntervenant
            });
        }
    }

    handleClickOpenEmail = () => {
        this.setState({ openEmail: !this.state.openEmail });
    };

    removeFile = (_, file) => {
        let newState = { ...this.state };
        newState.fileDemandeDevis = newState.fileDemandeDevis.filter(item => item !== file);
        newState.removeDemandeDevis = newState.removeDemandeDevis.concat(file);
        newState.hasChanges = true; // Mark changes when files are removed
        this.setState(newState);
    }

    stateModalSendMail = (event, row) => {
        if (row.file) {
            this.setState({
                alert: <Alert style={{ margin: 'auto', marginBottom: "20px", border: "1px solid", width: '50%' }} severity="warning">Sauvegarder la demande de devis et l'intervention pour pouvoir envoyé le mail !</Alert>
            });
        } else {
            this.setState({
                sendMailBool: true,
                alert: null,
                Attachment: [{ file: row.DemandeDevis, name: row.Name, id: row.ID, url: row.URL, base64_file: row.DemandeDevis }],
                hasChanges: true,
            });
        }
    }

    modalClosed = () => {
        this.setState({ sendMailBool: false });
    }

    closeCreateDemandeDevis = () => {
        this.setState({ openCreateDemandeDevis: false });
    }

    createDemandeDevis = (response) => {
        const newDemande = response.data.fileDemandeDevisIntervenant[0];
        this.setState(prevState => ({
            fileDemandeDevis: [...prevState.fileDemandeDevis, newDemande],
            hasChanges: true,
        }));
    }

    saveDemandeDevis = () => {
        this.props.saveDemandeDevis(this.state.fileDemandeDevis, this.state.removeDemandeDevis);
        this.props.modalClosed();
        this.setState({ hasChanges: false });
    }

    render() {
        const objetMail = `Mail Demande de Devis - Enseigne: ${this.props.infoIntervention.enseigne} N°${this.props.infoIntervention.numero_magasin} - DI Mastore ${this.props.infoIntervention.id_mastore} - DI Client ${this.props.infoIntervention.id_client} - ${this.props.infoIntervention.ville} [${this.props.infoIntervention.code_postal}] - ${this.props.infoIntervention.specialite}`;

        return (
            <React.Fragment>
                <Header>Demande de devis</Header>
                {this.state.alert}
                <Stack sx={{ p: 3 }} spacing={1}>
                    <div style={{ display: "flex" }}>
                        <CreateButton variant="outlined" size='small' onClick={() => { this.setState({ openCreateDemandeDevis: true }) }} startIcon={<IconCreate />}>
                            Créer une demande
                        </CreateButton>
                    </div>
                    <Stack style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
                        {this.state.fileDemandeDevis.length > 0 ? (
                            <TableEmailIntervenant nameFile="Devis" data={this.state.fileDemandeDevis} removeFile={this.removeFile} modalSendMail={this.stateModalSendMail} />
                        ) : (
                            <Typography variant="h1" align="center" textAlign={"center"} color="rgba(99, 115, 129, 0.3)">
                                Aucune demande de devis
                            </Typography>
                        )}
                    </Stack>
                </Stack>
                <DivButton sx={{ pb: 3 }}>
                    <SaveButton variant="outlined" size='small' onClick={this.saveDemandeDevis} disabled={!this.state.hasChanges}>
                        Sauvegarder
                    </SaveButton>
                </DivButton>
                {/* MODAL */}
                <Modal open={this.state.sendMailBool} close={this.modalClosed}>
                    <Mailbox
                        objectMail={objetMail}
                        Attachment={this.state.Attachment}
                        modalClosed={this.modalClosed}
                        destinataire={this.props.actions}
                        infoIntervention={this.props.infoIntervention}
                        mailuser={this.props.pilote_mail}
                        attribut="Demande de Devis"
                        sendto="intervenant"
                    />
                </Modal>
                <Modal open={this.state.openCreateDemandeDevis} close={this.closeCreateDemandeDevis} sx={{ width: { md: "80vw", sm: "80vw", xs: "95vw" }, maxHeight: "80vh" }}>
                    <CreateDevis
                        modalClosed={this.closeCreateDemandeDevis}
                        createDemandeDevis={this.createDemandeDevis}
                        infoIntervention={this.props.infoIntervention}
                        firstname={this.props.user.firstname}
                        lastname={this.props.user.lastname}
                        telephone={this.props.user.telephone}
                        numberfile={this.props.fileDemandeDevisIntervenant.length}
                    />
                </Modal>
            </React.Fragment>
        );
    }
}

export default DemandeDevis;
