import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import {
  Box,
  Stack,
  Button,
  Alert,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import Table from './Table';
import ModalInfosFiles from '../ModalInfosFiles/ModalInfosFiles';
import ModalEditInfosFiles from '../ModalInfosFiles/ModalEditInfosFiles';
import Header from 'components/UI/Header/Header';

function Inventaire(props) {
  const { filesPlan, data, addPlan, modalClosed } = props;
  
  const [files, setFiles] = useState(filesPlan);
  const [waitFiles, setWaitFiles] = useState([]);
  const [dataFiles, setDataFiles] = useState(filesPlan);
  const [openDataFile, setOpenDataFile] = useState(false);
  const [openEditDataFile, setOpenEditDataFile] = useState(false);
  const [editFile, setEditFile] = useState([]);
  const [alert, setAlert] = useState(null);

  const onDrop = (droppedFiles) => {
    let sizeOk = true;
    droppedFiles.forEach((file) => {
      if (file.size > 10_000_000) { // 10Mo
        sizeOk = false;
      }
    });

    if (sizeOk) {
      const newWaitFiles = droppedFiles.map(file => 
        Object.assign(file, { preview: URL.createObjectURL(file) })
      );
      setWaitFiles((prev) => [...prev, ...newWaitFiles]);
      setOpenDataFile(true);
      setAlert(null);
    } else {
      setAlert(
        <Alert 
          severity="warning"
          sx={{ 
            my: 2, 
            border: 1, 
            width: 'fit-content', 
            mx: 'auto' 
          }}
        >
          Limite de taille de fichier atteinte!  
          Taille maximale 10Mo. Aucun des fichiers n'a été ajouté.
        </Alert>
      );
    }
  };

  const handleAddPlan = () => {
    addPlan(files);
    modalClosed();
  };

  const removeFile = (_, file) => {
    const filteredFiles = files.filter(item => item !== file);
    setFiles(filteredFiles);
    setDataFiles(filteredFiles);
  };

  const saveOrCancel = (action, data) => {
    if (action === 'save') {
      setFiles((prev) => [...prev, ...data]);
      setWaitFiles([]);
      setDataFiles((prev) => [...prev, ...data]);
      setOpenDataFile(false);
    } else if (action === 'cancel') {
      setWaitFiles([]);
      setOpenDataFile(false);
    }
  };

  const editFileHandler = (_, file) => {
    setEditFile([file]);
    setOpenEditDataFile(true);
  };

  const saveOrCancelEditFile = (action, newFile, oldFile) => {
    if (action === 'save') {
      const index = files.indexOf(oldFile);
      const updatedFiles = [...files];
      updatedFiles[index] = newFile[0];
      setFiles(updatedFiles);
      setEditFile([]);
      setDataFiles(updatedFiles);
      setOpenEditDataFile(false);
    } else if (action === 'cancel') {
      setEditFile([]);
      setOpenEditDataFile(false);
    }
  };

  return (
    <Box sx={{ width: { md: "70vw", sm: "80vw", xs: "95vw" }, maxHeight: "80vh" }}>
      <Header>Inventaire</Header>
        <Stack sx={{ px: 3, maxHeight: 550, overflow: 'auto' }} spacing={2}>
            {openDataFile && (
            <ModalInfosFiles
                openDataFile={openDataFile}
                dataFiles={waitFiles}
                saveOrCancel={saveOrCancel}
            />
            )}

            {openEditDataFile && (
            <ModalEditInfosFiles
                openDataFile={openEditDataFile}
                dataFiles={editFile}
                saveOrCancel={saveOrCancelEditFile}
                type="Photos"
            />
            )}

        <Dropzone accept="image/*, application/pdf" onDrop={onDrop}>
          {({ getRootProps, getInputProps }) => (
            <Box {...getRootProps()} sx={{ width: 'fit-content' }}>
              <input {...getInputProps()} />
              <Button variant="outlined" startIcon={<AddIcon color='info' />}>
                Ajouter à l'inventaire
              </Button>
            </Box>
          )}
        </Dropzone>

        {alert && (
          <Box display="flex" justifyContent="center">
            {alert}
          </Box>
        )}

        <Table
          data={dataFiles}
          removeFile={removeFile}
          NumMag={data.NumeroMagasin}
          Enseigne={data.Enseigne}
          editFile={editFileHandler}
        />
      </Stack>

      <Stack sx={{ p: 3 }} direction="row" justifyContent="center" alignItems="center">
        <Stack sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
          <Button variant="outlined" onClick={handleAddPlan}>
            Sauvegarder l'inventaire
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
}

export default Inventaire;
