import React, { useState, useRef, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import PropTypes from "prop-types";

const CustomEditor = ({
                          initialData = "",
                          onSave,
                          placeholder = "Entrez votre texte ici...",
                          customStyles = {},
                      }) => {
    const [value, setValue] = useState(initialData);
    const quillRef = useRef(null);

    useEffect(() => {
        setValue(initialData); // Initialise l'éditeur avec les données passées via `initialData`.
    }, [initialData]);

    const handleChange = (content, delta, source, editor) => {
        setValue(content);
        saveEditorData(editor);
    };

    const saveEditorData = (editor) => {
        if (onSave) {
            const textData = editor.getHTML(); // Récupère le contenu sous forme HTML.
            onSave(textData); // Transmet les données sauvegardées via le callback.
        }
    };

    return (
            <ReactQuill
                ref={quillRef}
                value={value}
                onChange={handleChange}
                placeholder={placeholder}
                theme="snow" // Utilise le thème "snow".
                style={customStyles}
                modules={{
                    toolbar: [
                        ["bold", "italic", "underline", "strike"],
                        ["link"],
                        ["clean"], // Supprime la mise en forme.
                    ],
                }}
                formats={[
                    "header",
                    "bold",
                    "italic",
                    "underline",
                    "strike",
                    "list",
                    "bullet",
                    "link",
                ]}
            />
    );
};

CustomEditor.propTypes = {
    initialData: PropTypes.string,
    onSave: PropTypes.func.isRequired, // Callback pour enregistrer les données.
    placeholder: PropTypes.string,
    customStyles: PropTypes.object, // Styles personnalisés via props.
};

export default CustomEditor;
