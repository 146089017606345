import PropTypes from 'prop-types';
import { useEffect, Fragment } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Box, Stack, Drawer, Typography, Divider } from '@mui/material';
// hooks
import useResponsive from 'hooks/useResponsive';
import useCollapseDrawer from 'hooks/useCollapseDrawer';
// config
import { NAVBAR } from 'config';
import { navConfigChargeAffaire, navConfigAssistant, navConfigChargeAffaireAdjoint, navConfigAdmin, navConfigResponsable} from './NavConfig';
// components
import { NavSectionVertical } from 'navigation/nav-section';
import NavbarButtonView from './NavbarButtonView';
import NavbarFooter from './NavbarFooter';
import NavbarRelance from './NavbarRelance';
import NavbarTodoList from './NavbarTodoList';
import Search from './Search';
import CollapseButton from './CollapseButton';
import AllChats from 'components/UI/Chat/AllChats';
// Icon / logo
import logo from 'assets/img/LogoMastore.png';
import Badge from '@mui/material/Badge';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        flexShrink: 0,
        paddingLeft: 0,
        transition: theme.transitions.create('width', {
            duration: theme.transitions.duration.shorter,
        }),
    },
}));

const ScrollableNavSection = styled('div')(({ theme }) => ({
    flexGrow: 1,
    overflowY: 'auto',
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: 1,
        top: 0,
        border: `2px solid ${theme.palette.background.paper}`,
    },
}));

// ----------------------------------------------------------------------

NavbarVertical.propTypes = {
    isOpenSidebar: PropTypes.bool,
    onCloseSidebar: PropTypes.func,
    nbIntervention: PropTypes.number,
    onAccountInfortion: PropTypes.object,
    relanceInfo: PropTypes.object,
};

export default function NavbarVertical({ userInformation, isOpenSidebar, onCloseSidebar, nbIntervention, onAccountInfortion, relanceInfo, sortTabRelance }) {
    const theme = useTheme();
    const { pathname } = useLocation();
    const isDesktop = useResponsive('up', 'md');
    const { isCollapse, collapseClick, onToggleCollapse, onHoverEnter, onHoverLeave } =
        useCollapseDrawer();

    useEffect(() => {
        if (isOpenSidebar) {
            onCloseSidebar();
        }
    }, [pathname]);

    const renderContent = (
        <Box sx={{
            height: 1,
            display: 'flex', flexDirection: 'column',
            '& .simplebar-content': { width: 0.5, },
        }}>
            <Stack
                sx={{
                    padding: theme.spacing(1.5, 1),
                    flexShrink: 0,
                    justifyContent: "space-between",
                    ...(isCollapse && {
                        alignItems: 'center',
                        justifyContent: "center",
                    }),
                }}
                direction="row"
            >
                <img src={logo} alt={logo} style={{ height: "28px", width: "28px" }} />

                {isDesktop && !isCollapse && (
                    <Fragment>
                        <Typography variant="h4" sx={{ m: 'auto' }}>MASTORE</Typography>
                        <CollapseButton onToggleCollapse={onToggleCollapse} collapseClick={collapseClick} />
                    </Fragment>
                )}
            </Stack>

            <Stack
                sx={{
                    padding: theme.spacing(1),
                    justifyContent: 'center',
                    ...(isCollapse && {
                        alignItems: 'center',
                    }),
                }}
                direction={isCollapse ? "column" : "row"}
                divider={<Divider orientation="vertical" flexItem />}
                spacing={isCollapse ? 0 : 1.5}
            >
                {!isCollapse && (<NavbarButtonView />)}
                {!isCollapse && (<AllChats />)}
                <StyledBadge badgeContent={relanceInfo.nbOfRelance} color="error"  >
                    <NavbarRelance sortTabRelance={sortTabRelance} relanceInfo={relanceInfo.relanceByUser} onLoading={relanceInfo.loading} />
                </StyledBadge>
                {!isCollapse && (<NavbarTodoList />)}
            </Stack >

            <Search isCollapse={isCollapse} />

            <ScrollableNavSection>
                {(() => {
                    let navConfigToUse;

                    if (onAccountInfortion.role === "Assistant(e)" || onAccountInfortion.role === "Utilisateur") {
                        navConfigToUse = navConfigAssistant;
                    } else if (onAccountInfortion.role === "Chargé(e) d'affaires adjoint(e)") {
                        navConfigToUse = navConfigChargeAffaireAdjoint;
                    } else if ((onAccountInfortion.role === "Responsable maintenance" && onAccountInfortion.administrateur === true) || onAccountInfortion.role === "Développeur") {
                        navConfigToUse = navConfigAdmin;
                    } else if (onAccountInfortion.role === "Responsable maintenance") {
                        navConfigToUse = navConfigResponsable;
                    } else if (onAccountInfortion.role === "Chargé(e) d'affaires") {
                        navConfigToUse = navConfigChargeAffaire;
                    } else if (onAccountInfortion.role === "Comptable") {
                        navConfigToUse = navConfigChargeAffaire;
                    } else {
                        navConfigToUse = navConfigAssistant;
                    }

                    return (
                        <NavSectionVertical
                            nbIntervention={nbIntervention}
                            navConfig={navConfigToUse}
                            isCollapse={isCollapse}
                        />
                    );
                })()}
            </ScrollableNavSection>

            <NavbarFooter userInformation={userInformation} onAccountInfortion={onAccountInfortion} isCollapse={isCollapse} />
        </Box >
    );

    return (
        <RootStyle
            sx={{
                width: {
                    md: isCollapse ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH : NAVBAR.DASHBOARD_WIDTH,
                },
                ...(collapseClick && {
                    position: 'absolute',
                }),
            }}
        >
            {!isDesktop && (
                <Drawer open={isOpenSidebar} onClose={onCloseSidebar} PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH } }}>
                    {renderContent}
                </Drawer>
            )}

            {isDesktop && (
                <Drawer
                    open
                    variant="persistent"
                    onMouseEnter={onHoverEnter}
                    onMouseLeave={onHoverLeave}
                    PaperProps={{
                        sx: {
                            width: NAVBAR.DASHBOARD_WIDTH,
                            borderRightStyle: 'dashed',
                            background: '#fcfcfc',
                            transition: (theme) =>
                                theme.transitions.create('width', {
                                    duration: theme.transitions.duration.standard,
                                }),
                            ...(isCollapse && {
                                width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
                            }),

                        },
                    }}
                >
                    {renderContent}
                </Drawer>
            )}
        </RootStyle>
    );
}
