import React, { useEffect } from 'react';
import {
    Card,
    Grid,
    Stack,
    Box,
    Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { sumBy } from 'lodash';

// ----------------------------------------------------------------------

const StyledCard = styled(Card)(({ theme, margin }) => {
    return {
        color: 'white',
        height: "60px",
        padding: theme.spacing(1, 0.5),
        ...(margin >= 20 && {
            backgroundColor: theme.palette.warning.main,
        }),
        ...(margin >= 25 && {
            backgroundColor: theme.palette.success.dark,
        }),
        ...(margin < 20 && {
            backgroundColor: theme.palette.error.main,
        }),
        ...(margin <= 0 && {
            backgroundColor: 'grey',
        }),
    };
});

// ----------------------------------------------------------------------

const Marge = ({ factureClient, actions, devisClient }) => {
    const [income, setIncome] = React.useState(0);
    const [outgo, setOutgo] = React.useState(0);
    const [margin, setMargin] = React.useState(0);

    useEffect(() => {
        let totalclient = sumBy(factureClient, f => f.total_HT) || 0;
        let total_intervenant = 0;
        if (factureClient.length <= 0) {
            totalclient = sumBy(devisClient, f => parseFloat(f.totalHT)) || 0;
        }

        actions.forEach(action => {
            total_intervenant += sumBy(action.facture, f => f.total_HT) || 0;
            total_intervenant += sumBy(action.bon_de_commande, f => f.check ? f.total_HT : 0) || 0;
        });

        // Calcule de marge
        let earnings = totalclient - total_intervenant;
        if (totalclient === 0) {
            setMargin(0);
        } else {
            setMargin((earnings / totalclient) * 100);
        }
        setIncome(totalclient);
        setOutgo(total_intervenant);

    }, [factureClient, actions, devisClient]);

    return (
        <StyledCard margin={margin}>
            <Grid container style={{ height: "100%" }} justifyContent="center" alignItems="center">
                <Grid item xs={7} sx={{ fontWeight: "bold" }}>
                    <Stack direction={"column"} spacing={0.5}>
                        <Box>
                            Vendu: {income.toFixed(2)}€
                        </Box>
                        <Box>Acheté: {outgo.toFixed(2)}€
                        </Box>
                    </Stack>
                </Grid>
                <Grid item xs={5}>
                    <Stack direction={"column"} spacing={0} textAlign="center">
                        <Typography variant='h4'>
                            {margin >= 0 ? margin.toFixed(2) : "-" + (margin * -1).toFixed(2)}%
                        </Typography>
                        <Typography variant='subtitle2'>
                            {(income - outgo) >= 0 ? "+" + (income - outgo).toFixed(2) : (income - outgo).toFixed(2)}€
                        </Typography>
                    </Stack>
                </Grid>
            </Grid>
        </StyledCard>
    );
};

export default React.memo(Marge);