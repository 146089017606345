import React from 'react';
import { Button, Box } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';

const AstreinteButton = ({ infoIntervention, updateInfoIntervention }) => {
    return (
        <Button
            onClick={() => updateInfoIntervention(!infoIntervention.astreinte, 'astreinte')}
            color="primary"
            variant="outlined"
            size="small"
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start', // Contrôle l'alignement horizontal (par exemple, centré ou à gauche)
                gap: 1, // Espace entre les éléments
            }}
        >
            {/* Conteneur pour une gestion flexible */}
            <Box
                component="span"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    ml: 3,
                }}
            >
                <Checkbox checked={infoIntervention.astreinte} size="small" />
            </Box>
            <Box
                component="span"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                Astreinte
            </Box>
        </Button>
    );
};

export default AstreinteButton;
