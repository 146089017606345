import React from 'react'
import { Button, Box } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';

const SAVButton = ({ infoIntervention, updateInfoIntervention }) => {
    return (
        <Button
            onClick={() => updateInfoIntervention(!infoIntervention.sav, 'sav')}

            color={'primary'}
            variant="outlined"
            size='small'
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start', // Contrôle l'alignement horizontal (par exemple, centré ou à gauche)
                gap: 1, // Espace entre les éléments
            }}
        >
            <Box
                component="span"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    ml: 3,
                }}
            >
                <Checkbox checked={infoIntervention.sav} />
            </Box>
            <Box
                component="span"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                SAV MO
            </Box>
        </Button>
    )
}

export default SAVButton