import React from "react";
import { Button, Box } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";

const StandByButton = ({ infoIntervention, updateInfoIntervention }) => {
    const handleClick = () => {
        const newUrgency = infoIntervention.urgency === "standby" ? "" : "standby";
        updateInfoIntervention(newUrgency, "urgency");
    };

    return (
        <Button
            onClick={handleClick}

            color={"primary"}
            variant="outlined"
            size="small"
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start', // Contrôle l'alignement horizontal (par exemple, centré ou à gauche)
                gap: 1, // Espace entre les éléments
            }}
        >
            <Box
                component="span"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    ml: 3,
                }}
            >
                <Checkbox checked={infoIntervention.urgency === "standby"} />
            </Box>
            <Box
                component="span"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                STANDBY
            </Box>
        </Button>
    );
};

export default StandByButton;
