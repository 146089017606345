import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from 'axios';
import 'moment/locale/fr';
import moment from 'moment';
import InterventionView from "./InterventionView";
import { connect } from 'react-redux';
import { cloneDeep, findIndex } from "lodash";
import { useSnackbar } from 'notistack';
import { formatDate } from 'utils/date';
import DocumentService from "services/documentsService";

function InterventionContainer({ user }) {
    const location = useLocation();
    const [infoIntervention, setInfoIntervention] = useState([]);
    const [actions, setActions] = useState([]);
    const [clientInfo, setClientInfo] = useState([]);
    const [update, setUpdate] = useState(false);
    const [loading, setLoading] = useState(true);
    const [createdActions, setCreatedActions] = useState([]);
    const [deletedActions, setDeletedActions] = useState([]);
    const [modifiedActions, setModifiedActions] = useState([]);
    const [createdDateIntervention, setCreatedDateIntervention] = useState([]);
    const [modifiedDateIntervention, setModifiedDateIntervention] = useState([]);
    const [deletedDateIntervention, setDeletedDateIntervention] = useState([]);
    const [automatiqueComments, setAutomatiqueComments] = useState([]);

    const { enqueueSnackbar } = useSnackbar();
    const documentService = new DocumentService();

    const isSaveDisabled = actions.every(action => action.etat === "ARCHIVEE")


    useEffect(() => {
        let params = new URLSearchParams(location.search);
        const id_mastore = params.get("IDMastore");
        axios.defaults.withCredentials = true;
        Promise.allSettled([
            axios.get(`/legacy/interventionDataByID?id_mastore=${id_mastore}`),
            documentService.getDevis(id_mastore),
        ]).then(([
            interventionResponse,
            devisResponse,
        ]) => {
            if (interventionResponse.status !== "rejected") {
                const interventionData = interventionResponse.value.data;
                const clientInfo = interventionData.clientInfo || {};
                clientInfo.devis = devisResponse.status !== "rejected" ? devisResponse.value.devis : [];
                setInfoIntervention(interventionData.intervention);
                setActions(interventionData.actions);
                setClientInfo(clientInfo);
            }
            setLoading(false);
        });
    }, [location.search]);



    const saveIntervention = () => {
        const header = {
            InfoIntervention: infoIntervention,
            ModifiedAction: modifiedActions,
            CreatedAction: createdActions,
            DeletedAction: deletedActions,
            ModifiedDateIntervention: modifiedDateIntervention,
            CreatedDateIntervention: createdDateIntervention,
            DeletedDateIntervention: deletedDateIntervention,
        };

        let statusPriority = 0;
        let finalStatus = null;

        actions.forEach(item => {
            const { etat } = item;
            if (["A PLANIFIER", "ATTENTE INTERVENANT", "A CHIFFRER"].includes(etat) && statusPriority < 2) {
                finalStatus = "En cours";
                statusPriority = 2;
            } else if (["A SUIVRE", "A CLORE"].includes(etat) && statusPriority < 3) {
                finalStatus = "Programmée";
                statusPriority = 3;
            } else if (etat === "CLOTUREE" && statusPriority < 1) {
                finalStatus = "Terminée";
                statusPriority = 1;
            } else if (["ATTENTE CLIENT", "ATTENTE VALIDATION CLIENT"].includes(etat) && statusPriority === 0) {
                finalStatus = "À traiter";
            }
        });
        if (finalStatus) {
            header.InfoIntervention.client_status = finalStatus;
        }

        axios.defaults.withCredentials = true;
        return axios.put('/legacy/intervention', header)
    };

    const saveAutomatiqueCommentaire = () => {
        let header = [];
        automatiqueComments.forEach(item => {
            if (item.key === "info_site" || item.key === "nuit" || item.key === "date") {
                const indexAction = actions.map(item => item.ID).indexOf(item.action_id);
                const indexDateIntervention = actions[indexAction].date_intervention.map(item => item.ID).indexOf(item.date_id);
                const dateIntervention = actions[indexAction].date_intervention[indexDateIntervention]

                if (item.key === "info_site" && dateIntervention.info_site !== item.old_value)
                    header.push({
                        IDMastore: infoIntervention.id_mastore,
                        author: `${user.firstname} ${user.lastname}`,
                        commentaire: `${actions[indexAction].intervenant.societe}: ${formatDate(dateIntervention.date, 'dddd DD MMM, H:mm')} => ${!item.old_value ? 'info site prévenu' : 'info site annulé'}`,
                        color: !item.old_value ? '1' : '2',
                        time: item.date
                    });
                if (item.key === "date" && dateIntervention.date !== item.old_value)
                    header.push({
                        IDMastore: infoIntervention.id_mastore,
                        author: `${user.firstname} ${user.lastname}`,
                        commentaire: `${actions[indexAction].intervenant.societe}: date modifié => ${formatDate(dateIntervention.date, 'dddd DD MMM, H:mm')}`,
                        color: "1",
                        time: item.date
                    });
            } else {
                const indexAction = actions.map(item => item.ID).indexOf(item.action_id);
                if (item.type === 'new')
                    header.push({
                        IDMastore: infoIntervention.id_mastore,
                        author: `${user.firstname} ${user.lastname}`,
                        commentaire: `${actions[indexAction].intervenant.societe}: intervenant ajouté`,
                        color: "1",
                        time: item.date
                    });
                else
                    header.push({
                        IDMastore: infoIntervention.id_mastore,
                        author: `${item.old_value !== '' ? item.old_value : "Action"}: intervenant supprimé`,
                        color: "2",
                        time: item.date
                    });
            }
        });

        axios.defaults.withCredentials = true;
        axios.post('/legacy/addCommentaire', header)
    };

    const updateInfoIntervention = (value, key) => {
        let infoInterventionTmp = { ...infoIntervention }

        if (key === "status" && infoIntervention.contact_client === "")
            enqueueSnackbar("Veuillez choisir le contact client", { variant: 'warning' });
        else
            infoInterventionTmp[key] = value;
        setInfoIntervention(infoInterventionTmp);
        setUpdate(true);
    }

    const automatiqueCommentsUpdate = (action_id, date_id, old_value, key) => {
        const index = findIndex(automatiqueComments, { action_id: action_id, key: key, date_id: date_id })

        if (index === -1)
            setAutomatiqueComments([...automatiqueComments, { action_id: action_id, date_id: date_id, type: 'modify', old_value: old_value, key: key, date: new Date() }])
        else {
            let automatiqueCommentsTmp = cloneDeep(automatiqueComments)
            automatiqueCommentsTmp[index].date = new Date()
            setAutomatiqueComments(automatiqueCommentsTmp)
        }
    }

    const automatiqueCommentsDelete = (action_id, key, date_id, old_value) => {
        const index = findIndex(automatiqueComments, { action_id: action_id, key: key, date_id: date_id, type: 'new' })
        let automatiqueCommentsTmp = cloneDeep(automatiqueComments)
        automatiqueCommentsTmp.forEach((item, index) => {
            if (item.action_id === action_id)
                automatiqueCommentsTmp.splice(index, 1)
        });
        if (index === -1)
            automatiqueCommentsTmp.push({ action_id: action_id, date_id: 0, type: 'delete', old_value: old_value, key: key, date: new Date() })
        setAutomatiqueComments(automatiqueCommentsTmp)
    }

    const newAction = () => {
        let actionsTmp = cloneDeep(actions)
        let createdActionsTmp = cloneDeep(createdActions)

        const newAction = {
            ID: Date.now(),
            intervenant_id: -1,
            id_mastore: infoIntervention.id_mastore,
            etat: "A PLANIFIER",
            total: 0.00,
            completed: false,
            date_action: new Date(),
            date_intervention: [],
            intervenant: {},
            bon_de_commande: [],
            facture: [],
        }
        createdActionsTmp = createdActionsTmp.concat(newAction)
        actionsTmp.unshift(newAction)

        setCreatedActions(createdActionsTmp)
        setUpdate(true)
        setActions(actionsTmp)
    }

    const modifyAction = (key, value, action_id, setActionsBool) => {
        let indexAction = actions.map(item => item.ID).indexOf(action_id);
        let indexCreatedAction = createdActions.map(item => item.ID).indexOf(action_id);
        let actionsTmp = cloneDeep(actions)
        actionsTmp[indexAction][key] = value

        if (key === "intervenant") {
            actionsTmp[indexAction].intervenant_id = value.ID
            setAutomatiqueComments([...automatiqueComments, { action_id: action_id, date_id: 0, type: 'new', old_value: '', key: 'action', date: new Date() }])
        }

        if (indexCreatedAction !== -1) {
            let createdActionsTmp = cloneDeep(createdActions)
            if (key === "intervenant") {
                createdActionsTmp[indexCreatedAction].intervenant_id = value.ID
            } else {
                createdActionsTmp[indexCreatedAction][key] = value
            }
            setCreatedActions(createdActionsTmp)
        } else {
            let indexModifiedActions = modifiedActions.map(item => item.ID).indexOf(action_id);
            let modifiedActionsTmp = cloneDeep(modifiedActions)
            if (indexModifiedActions !== -1) {
                modifiedActionsTmp[indexModifiedActions][key] = value
            } else {
                modifiedActionsTmp = modifiedActionsTmp.concat(actionsTmp[indexAction])
            }
            setModifiedActions(modifiedActionsTmp)
        }
        if (setActionsBool)
            setActions(actionsTmp)
        setUpdate(true)
    }

    const deleteAction = (action) => {
        let confirmAction = window.confirm("Toutes les factures et devis liés à cette action seront supprimé\nVoulez-vous supprimer l'action ?")

        if (confirmAction) {
            let indexAction = actions.map(item => item.ID).indexOf(action.ID);
            let indexCreatedActions = createdActions.map(item => item.ID).indexOf(action.ID);
            let indexModifiedActions = modifiedActions.map(item => item.ID).indexOf(action.ID);

            let actionsTmp = cloneDeep(actions)
            let deletedActionsTmp = cloneDeep(deletedActions)
            let deletedDateInterventionTmp = cloneDeep(deletedDateIntervention)
            let createdDateInterventionTmp = cloneDeep(createdDateIntervention)
            let modifiedDateInterventionTmp = cloneDeep(modifiedDateIntervention)

            deletedDateInterventionTmp.forEach((item, index) => {
                if (item.action_id === action.ID) {
                    deletedDateInterventionTmp.splice(index, 1)
                }
            });
            createdDateInterventionTmp.forEach((item, index) => {
                if (item.action_id === action.ID) {
                    createdDateInterventionTmp.splice(index, 1)
                }
            });
            modifiedDateInterventionTmp.forEach((item, index) => {
                if (item.action_id === action.ID) {
                    modifiedDateInterventionTmp.splice(index, 1)
                }
            });

            deletedActionsTmp.push(action)
            actionsTmp.splice(indexAction, 1)
            if (indexCreatedActions !== -1) {
                let createdActionsTmp = cloneDeep(createdActions)
                createdActionsTmp.splice(indexCreatedActions, 1)
                setCreatedActions(createdActionsTmp)
            }
            if (indexModifiedActions !== -1) {
                let modifiedActionsTmp = cloneDeep(modifiedActions)
                modifiedActionsTmp.splice(indexModifiedActions, 1)
                setModifiedActions(modifiedActionsTmp)
            }
            if (actionsTmp.length === 0) {
                let createdActionsTmp = cloneDeep(createdActions)
                const newAction = {
                    ID: Date.now(),
                    intervenant_id: -1,
                    id_mastore: infoIntervention.id_mastore,
                    etat: "A PLANIFIER",
                    total: 0.00,
                    completed: false,
                    date_action: new Date(),
                    date_intervention: [],
                    intervenant: {},
                    bon_de_commande: [],
                    facture: [],
                }
                createdActionsTmp = createdActionsTmp.concat(newAction)
                actionsTmp.unshift(newAction)
                setCreatedActions(createdActionsTmp)
            }

            automatiqueCommentsDelete(action.ID, 'action', 0, actions[indexAction].intervenant.societe)
            setCreatedDateIntervention(createdDateInterventionTmp)
            setModifiedDateIntervention(modifiedDateInterventionTmp)
            setDeletedDateIntervention(deletedDateInterventionTmp)
            setActions(actionsTmp)
            setDeletedActions(deletedActionsTmp)
            setUpdate(true)
        }
    }

    const newDateIntervention = (action) => {
        let indexAction = actions.map(item => item.ID).indexOf(action.ID);

        let actionsTmp = cloneDeep(actions)
        let createdDateInterventionTmp = cloneDeep(createdDateIntervention)
        const newDate = {
            ID: Date.now(),
            intervenant_id: action.intervenant_id,
            action_id: action.ID,
            date: moment({ hour: 8 }),
            duration: "02:00",
            infoSite: false,
            nuit: false,
            materiels: false,
            intervention_id_mastore: infoIntervention.id_mastore,
        }
        actionsTmp[indexAction].date_intervention.unshift(newDate)
        createdDateInterventionTmp = createdDateInterventionTmp.concat(newDate)

        if (actionsTmp[indexAction].etat !== "A SUIVRE") {
            actionsTmp[indexAction].etat = "A SUIVRE"
            modifyAction('etat', "A SUIVRE", action.ID, false)
        }

        setActions(actionsTmp)
        setCreatedDateIntervention(createdDateInterventionTmp)
        setUpdate(true)
    }

    const modifyDateIntervention = (key, value, action_id, date_id) => {
        let indexAction = actions.map(item => item.ID).indexOf(action_id);
        let indexDateIntervention = actions[indexAction].date_intervention.map(item => item.ID).indexOf(date_id);
        let indexCreatedDateIntervention = createdDateIntervention.map(item => item.ID).indexOf(date_id);

        if (key !== "duration")
            automatiqueCommentsUpdate(action_id, date_id, actions[indexAction].date_intervention[indexDateIntervention][key], key)

        let actionsTmp = cloneDeep(actions)
        actionsTmp[indexAction].date_intervention[indexDateIntervention][key] = value

        if (indexCreatedDateIntervention !== -1) {
            let createdDateInterventionTmp = cloneDeep(createdDateIntervention)
            createdDateInterventionTmp[indexCreatedDateIntervention][key] = value
            setCreatedDateIntervention(createdDateInterventionTmp)
        } else {
            let indexModifiedDateIntervention = modifiedDateIntervention.map(item => item.ID).indexOf(date_id);
            let modifiedDateInterventionTmp = cloneDeep(modifiedDateIntervention)
            if (indexModifiedDateIntervention !== -1) {
                modifiedDateInterventionTmp[indexModifiedDateIntervention][key] = value
            } else {
                modifiedDateInterventionTmp = modifiedDateInterventionTmp.concat(actionsTmp[indexAction].date_intervention[indexDateIntervention])
            }
            setModifiedDateIntervention(modifiedDateInterventionTmp)
        }
        setActions(actionsTmp)
        setUpdate(true)
    }

    const deleteDateIntervention = (action_id, date_id) => {
        let indexAction = actions.map(item => item.ID).indexOf(action_id);
        let indexDateIntervention = actions[indexAction].date_intervention.map(item => item.ID).indexOf(date_id);
        let indexCreatedDateIntervention = createdDateIntervention.map(item => item.ID).indexOf(date_id);
        let indexModifiedDateIntervention = modifiedDateIntervention.map(item => item.ID).indexOf(date_id);

        let actionsTmp = cloneDeep(actions)
        let deletedDateInterventionTmp = cloneDeep(deletedDateIntervention)

        deletedDateInterventionTmp.push(actionsTmp[indexAction].date_intervention[indexDateIntervention])
        actionsTmp[indexAction].date_intervention.splice(indexDateIntervention, 1)
        if (indexCreatedDateIntervention !== -1) {
            let createdDateInterventionTmp = cloneDeep(createdDateIntervention)
            createdDateInterventionTmp.splice(indexCreatedDateIntervention, 1)
            setCreatedDateIntervention(createdDateInterventionTmp)
        }
        if (indexModifiedDateIntervention !== -1) {
            let modifiedDateInterventionTmp = cloneDeep(modifiedDateIntervention)
            modifiedDateInterventionTmp.splice(indexModifiedDateIntervention, 1)
            setModifiedDateIntervention(modifiedDateInterventionTmp)
        }
        if (actionsTmp[indexAction].date_intervention.length <= 0) {
            actionsTmp[indexAction].etat = "A PLANIFIER"
            modifyAction('etat', "A PLANIFIER", action_id, false)
        }
        setActions(actionsTmp)
        setDeletedDateIntervention(deletedDateInterventionTmp)
        setUpdate(true)
    }

    const isAllActionsClosed = (actions && Array.isArray(actions)) ? actions.every(action => action.etat === 'CLOTUREE' || action.etat === 'ARCHIVEE') : false;
    const isAllDevisValid = (clientInfo.devis && Array.isArray(clientInfo.devis)) ? clientInfo.devis.every(devis => ['VALIDÉ', 'REFUSÉ', 'ARCHIVÉ'].includes(devis.status)) : false;

    const isArchiveDisabled = 
    Array.isArray(clientInfo.devis) && clientInfo.devis.some(devis => devis.status !== "ARCHIVÉ" && devis.status !== "REFUSÉ");
    
    const hasSentDevis = 
    Array.isArray(clientInfo.devis) && clientInfo.devis.some(devis => devis.status === "ENVOYÉ");
    
    return (
        loading ? null :
            <InterventionView
                actions={actions}
                clientInfo={clientInfo}
                infoIntervention={infoIntervention}
                updateInfoIntervention={updateInfoIntervention}
                saveIntervention={saveIntervention}
                saveAutomatiqueCommentaire={saveAutomatiqueCommentaire}
                update={update}
                user={user}
                setClientInfo={setClientInfo}
                setActions={setActions}
                newDateIntervention={newDateIntervention}
                modifyDateIntervention={modifyDateIntervention}
                deleteDateIntervention={deleteDateIntervention}
                newAction={newAction}
                modifyAction={modifyAction}
                deleteAction={deleteAction}
                isAllActionsClosed={isAllActionsClosed}
                isAllDevisValid={isAllDevisValid}
                createdActions={createdActions}
                isSaveDisabled={isSaveDisabled}
                isArchiveDisabled={isArchiveDisabled}
                hasSentDevis={hasSentDevis}
            />
    );
}

const mapStateToProps = state => {
    return {
        user: { ...state }
    };
};

export default connect(mapStateToProps)(InterventionContainer);
