import React, { useEffect, useState } from "react";
import { Card, Grid, TextField, Stack, Box, IconButton } from "@mui/material";
import FitScreenIcon from "@mui/icons-material/FitScreen";
import { styled } from "@mui/material/styles";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import MapIcon from "@mui/icons-material/Map";
import DescriptionIcon from "@mui/icons-material/Description";
import Modal from "components/UI/Modal";
import InventoryIcon from "@mui/icons-material/Inventory";
import Commentaire from "./Commentaire/Commentaire";
import ModalButton from "components/UI/ModalButton";
import Marge from "./Marge/Marge";
import SAVButton from "./SAVButton";
import StandByButton from "./StandByButton";
import AstreinteButton from "./AstreinteButton";
import InterventionCalendar from "./InterventionCalendar/InterventionCalendar";
import CommandeStocks from "../modal/CommandeStock/CommandeStock";

import StockService from "services/stocksService";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Inclure les styles par défaut de Quill

const stockService = new StockService();

const ExtandableTextArea = styled("textarea", {
    shouldForwardProp: (prop) => prop !== "expand",
})(({ theme, expand }) => ({
    border: "1px solid rgb(196 196 196)",
    resize: "none",
    padding: "8px 14px",
    height: "100%",
    borderRadius: "4px",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontSize: theme.typography.body2.fontSize,
    outline: "none",
    width: "100%",
    zIndex: 0,
    "&:hover": {
        border: "1px solid #000",
    },
    "&:focus": {
        border: "2px solid #30668f",
    },
    ...(expand && {
        zIndex: 1,
        position: "absolute",
        bottom: 0,
    }),
}));

const BoxRelative = styled(Box, {
    shouldForwardProp: (prop) => prop !== "expand",
})(({ expand }) => ({
    height: "100%",
    width: "100%",
    position: "relative",
    ...(expand && {
        zIndex: 3,
        position: "absolute",
        bottom: 0,
    }),
}));

// ----------------------------------------------------------------------

const InterventionInformation = (props) => {
    const [expandPrestation, setExpandPrestation] = useState(false);
    const [expandDemande, setExpandDemande] = useState(false);
    const [openCommandeStock, setOpenCommandeStock] = useState(false);
    const [isCommandeEmpty, setIsCommandeEmpty] = useState(true);
    const [editorContent, setEditorContent] = useState(
        props.infoIntervention.complement_objet || ""
    );

    const capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    const formatString = (value) => {
        props.updateInfoIntervention(
            capitalize(
                value
                    .trim()
                    .replace(/[&/\\#,+()$~%.'":*?<>{}]/g, "")
                    .toLowerCase()
            ),
            "objet"
        );
    };

    useEffect(() => {
        stockService
            .getFicheCommande(props.infoIntervention.id_mastore)
            .then((data) => {
                setIsCommandeEmpty(data.length === 0); // Set state based on data
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }, [props.infoIntervention.id_mastore]);

    const handleQuillChange = (content, delta, source, editor) => {
        setEditorContent(content);
        props.updateInfoIntervention(content, "complement_objet");
    };

    return (
        <Card variant="outlined" sx={{ p: 1 }}>
            <Stack direction="column" sx={{ height: "100%" }} spacing={1}>
                <Grid container spacing={0.5} sx={{ flex: "1 1 auto" }}>
                    <Grid item xs={12} sm={9}>
                        <Stack spacing={1} sx={{ height: "100%", position: "relative" }}>
                            <TextField
                                defaultValue={props.infoIntervention.objet}
                                placeholder="Objet de la demande"
                                variant="outlined"
                                size="small"
                                onBlur={(event) => formatString(event.target.value)}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                            />
                            <BoxRelative
                                expand={expandDemande}
                                style={
                                    !expandDemande
                                        ? props.infoIntervention.type === "préventive"
                                            ? { height: "30%" }
                                            : { height: "40%" }
                                        : null
                                }
                            >
                                <IconButton
                                    color={expandDemande ? "primary" : "secondary"}
                                    component="span"
                                    style={{ position: "absolute", top: 0, right: 0, zIndex: 2 }}
                                    onClick={() => setExpandDemande(!expandDemande)}
                                >
                                    <FitScreenIcon />
                                </IconButton>
                                <ExtandableTextArea
                                    placeholder="Demande initiale"
                                    rows={4}
                                    value={props.infoIntervention.initial_request}
                                    onChange={(event) =>
                                        props.updateInfoIntervention(
                                            event.target.value,
                                            "initial_request"
                                        )
                                    }
                                    expand={expandDemande}
                                />
                            </BoxRelative>
                            <BoxRelative expand={expandPrestation}
                                         sx={{flexGrow: 1, display: 'flex', flexDirection: 'column'}}>
                                <IconButton
                                    color={expandPrestation ? "primary" : "secondary"}
                                    component="span"
                                    style={{ position: "absolute", top: 0, right: 0, zIndex: 2 }}
                                    onClick={() => setExpandPrestation(!expandPrestation)}
                                >
                                    <FitScreenIcon />
                                </IconButton>
                                <ReactQuill
                                    theme="snow"
                                    value={editorContent}
                                    onChange={handleQuillChange}
                                    modules={{
                                        toolbar: [
                                            ["bold", "italic", "underline", "strike"],
                                            ["link"],
                                            ["clean"],
                                        ],
                                    }}
                                    style={{
                                        height: expandPrestation ? "auto" : "300px",
                                        overflowY: "auto",
                                        borderBottom: "1px solid rgb(196 196 196)",
                                        background: "#fff",
                                        flexGrow: 1,
                                    }}
                                />
                                <style>
                                {`
                                .ql-toolbar.ql-snow {
                                    border-top-right-radius: 3px;
                                    border-top-left-radius: 3px;
                                }
                                .ql-container.ql-snow {
                                    border-bottom-right-radius: 3px;
                                    border-bottom-left-radius: 3px;
                                }
                                `}
                            </style>
                            </BoxRelative>
                            <Commentaire IDMastore={props.infoIntervention.id_mastore} />
                        </Stack>
                    </Grid>
                    <Grid item sm={3} xs={12}>
                        <Stack direction={"column"} spacing={1}>
                            {!JSON.parse(localStorage.getItem("clientView")) ? (
                                <Marge
                                    actions={props.actions}
                                    factureClient={props.factureClient}
                                    devisClient={props.devisClient}
                                />
                            ) : null}
                            <Box
                                sx={{
                                    height: "fit-content",
                                    display: { xs: "none", md2: "flex" },
                                    justifyContent: "center",
                                }}
                            >
                                <InterventionCalendar
                                    actions={props.actions}
                                    dateMax={props.infoIntervention.date_max}
                                />
                            </Box>
                            <TextField
                                multiline
                                label="Spécificité(s) Mastore"
                                defaultValue={props.infoIntervention.specificite_mastore}
                                placeholder="Spécificité(s) Mastore"
                                variant="outlined"
                                size="small"
                                rows={10}
                                rowsmax={10}
                                onBlur={(event) =>
                                    props.updateInfoIntervention(
                                        event.target.value,
                                        "specificite_mastore"
                                    )
                                }
                                InputLabelProps={{ shrink: true }}
                            />
                            <SAVButton
                                infoIntervention={props.infoIntervention}
                                updateInfoIntervention={props.updateInfoIntervention}
                            />
                            <AstreinteButton
                                infoIntervention={props.infoIntervention}
                                updateInfoIntervention={props.updateInfoIntervention}
                            />
                            {/* StandByButton ajouté ici */}
                            <StandByButton
                                infoIntervention={props.infoIntervention}
                                updateInfoIntervention={props.updateInfoIntervention}
                            />
                        </Stack>
                    </Grid>
                </Grid>
                <Stack direction={"row"} justifyContent="space-around">
                    <ModalButton
                        name="Multimédia(s)"
                        filled={props.boolPhotos > 0}
                        clickHandler={props.openModalPhoto}
                        icon={<PhotoLibraryIcon />}
                    />
                    <ModalButton
                        name="Plan(s)"
                        filled={props.filePlanLength > 0}
                        clickHandler={props.openPlanModal}
                        icon={<MapIcon />}
                    />
                    <ModalButton
                        name="Document(s)"
                        filled={props.fileDocumentLength > 0}
                        clickHandler={props.openDocumentModal}
                        icon={<DescriptionIcon />}
                    />
                    <ModalButton
                        name="Commande Stocks"
                        filled={!isCommandeEmpty}
                        clickHandler={() => setOpenCommandeStock(true)}
                        icon={<InventoryIcon />}
                    />
                </Stack>
                <Modal
                    open={openCommandeStock}
                    close={() => setOpenCommandeStock(false)}
                    padding="0px"
                >
                    <CommandeStocks
                        infoIntervention={props.infoIntervention}
                        user={props.user}
                        actions={props.actions}
                    />
                </Modal>
            </Stack>
        </Card>
    );
};

export default InterventionInformation;
