import React from 'react';
import { Button, TextField, Stack } from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import axios from 'axios';
import Typography from '@mui/material/Typography';

import FlexFooter from 'components/UI/FlexFooter';
import Modal from 'components/UI/Modal'
import Header from 'components/UI/Header/Header';
import Spinner from 'components/UI/Spinner/Spinner';
import ModalPhoto from 'components/UI/ModalPhoto/ModalPhoto'
import CustomEditor from 'components/UI/CustomEditor';



// Fonction alert pour error request
function AlertMui(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const CreateDevis = (props) => {
    const [object, setObject] = React.useState(props.infoIntervention.objet);
    const [description, setDescription] = React.useState(props.infoIntervention.complement_objet);
    const [modalPhoto, setModalPhoto] = React.useState(false)
    const [attachment, setAttachment] = React.useState([])
    const [formAttachment, setFormAttachment] = React.useState(null)
    const [photo] = React.useState([])
    const [spinner, setSpinner] = React.useState(null)

    // Alert Erreur request
    const [openAlertErrorRequest, setOpenAlertErrorRequest] = React.useState(false)
    const [alertErrorRequest, setAlertErrorRequest] = React.useState(null)

    const changeObject = (event) => {
        setObject(event.target.value.replace(/[&/\\#,+()$~%.'":*?<>{}]/g, ""))
    };
    const changeDescription = (event) => {
        setDescription(event.target.value)
    };

    const handleModalPhoto = () => {
        setModalPhoto(!modalPhoto)
    }

    const addAttachment = (files) => {
        setAttachment(files)
        const form = files.map(file => {
            return <p key={file.id} style={{
                margin: 'auto 0',
                borderRight: '1px solid #316690',
                padding: '0 5px',
                color: '#316690'
            }}
            >{file.file_name}</p>
        })
        setFormAttachment(form)
    }

    // Create demande de deivs
    const createDemandeDevis = () => {
        let attachmentSend = attachment.map(att => {
            if (att.BonDeCommande)
                return att.BonDeCommande
            else if (att.Facture)
                return att.Facture
            else if (att.file)
                return att.file
            return null
        })
        let attachmentName = attachment.map(att => {
            if (att.name)
                return att.name
            else if (att.Name)
                return att.Name
            else if (att.file_name)
                return att.file_name
            return null
        })
        setSpinner(<Spinner />)
        const header = {
            Pilote: props.firstname + " " + props.lastname,
            Tel: props.telephone,
            MastoreDI: props.infoIntervention.id_mastore,
            Enseigne: props.infoIntervention.enseigne,
            Implantation: props.infoIntervention.implantation,
            Adresse: props.infoIntervention.adresse,
            CodeMagasin: props.infoIntervention.numero_magasin,
            Ville: props.infoIntervention.ville,
            CodePostal: props.infoIntervention.code_postal,
            TelMag: props.infoIntervention.telephone,
            MailMag: props.infoIntervention.mail,
            NumDi: props.infoIntervention.id_client,
            DomaineActivite: props.infoIntervention.specialite,
            Objet: object,
            initial_request: props.infoIntervention.initial_request,
            Description: description,
            Attachment: attachmentSend,
            NameAttachment: attachmentName,
            withCredentials: true,
            Name: `${props.numberfile + 1} - Demande de devis - ${props.infoIntervention.id_mastore} - ${props.infoIntervention.enseigne} ${props.infoIntervention.ville} ${props.infoIntervention.numero_magasin} - ${object} - ${props.infoIntervention.id_client}.pdf`
        }
        axios.defaults.withCredentials = true
        axios.post('/legacy/CreateDemandeDevisIntervenant', header)
            .then(response => {
                props.modalClosed()
                props.createDemandeDevis(response)
            })
            .catch(error => {
                setOpenAlertErrorRequest(true)
                setAlertErrorRequest(<AlertMui onClose={handleCloseAlertErrorRequest} severity="error">
                    Erreur création de la demande de devis!
                </AlertMui>)
            });
    }

    // <-- Function Alert error Request -->

    const handleCloseAlertErrorRequest = (event, reason) => {
        setOpenAlertErrorRequest(false)
        setAlertErrorRequest(null)
    }

    // <-- End Function Alert error Request -->

    return (
        <React.Fragment>
            <Header>Créer une demande de devis</Header>
            <Stack spacing={1} sx={{ px: 3, maxHeight: '80vh', overflowY: 'auto' }}>
                <Stack spacing={0.5}>
                    <Typography variant="h6" color="primary">Objet :</Typography>
                    <TextField
                        variant="outlined"
                        value={object}
                        type="text"
                        onChange={changeObject.bind(this)}
                        name="Objet"
                        size='small'
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Stack>
                <Stack spacing={0.5}>
                    <Typography variant="h6" color="primary">Description :</Typography>
                    <CustomEditor
                        initialData={description}
                        onSave={(data) => setDescription(data)}
                        placeholder="Entrez votre texte ici..."
                    />
                </Stack>

                <Stack direction={'row'}>
                    <Button
                        variant="outlined"
                        startIcon={<ImageIcon />}
                        onClick={handleModalPhoto}
                    >
                        Photo(s)
                    </Button>
                    {formAttachment}
                </Stack>
            </Stack>
            <FlexFooter style={{ position: 'sticky', bottom: 0, background: '#fff', zIndex: 1000 }}>
                <Button variant="outlined" onClick={createDemandeDevis}>
                    Créer la demande
                </Button>
            </FlexFooter>

            <Modal open={modalPhoto} close={handleModalPhoto}>
                <ModalPhoto photo={photo} close={handleModalPhoto} attachment={attachment} setAttachment={addAttachment} IDMastore={props.infoIntervention.id_mastore} />
            </Modal>
            <Snackbar open={openAlertErrorRequest} autoHideDuration={6000} onClose={handleCloseAlertErrorRequest}>
                {alertErrorRequest}
            </Snackbar>
            {spinner}
        </React.Fragment >
    );
}

export default CreateDevis;