import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { openFile } from "utils/handleFiles";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

function SlideImage({ media, wSize, hSize }) {
    const theme = useTheme();
    const [activeStep, setActiveStep] = useState(0);
    const maxSteps = media.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    if (!wSize) wSize = "330px";
    if (!hSize) hSize = "300px";

    return (
        <Box
            sx={{
                maxWidth: "330px",
                maxHeight: "400px",
                display: 'block',
                margin: 'auto',
                cursor: 'pointer',
            }}
        >
            <AutoPlaySwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
                autoplay={false}
            >
                {media.map((item) => (
                    <div key={item.id}>
                        {item.type === 'image' ? (
                            <Box
                                component="img"
                                sx={{
                                    width: wSize,
                                    height: hSize,
                                    objectFit: "contain",
                                }}
                                onClick={() => openFile(item.file, 'image/png;base64')}
                                src={`data:image/*;base64,${item.file}`}
                                alt={item.name}
                            />
                        ) : (
                            <Box
                                component="video"
                                sx={{
                                    width: wSize,
                                    height: hSize,
                                    objectFit: "contain",
                                }}
                                controls
                                onClick={() => openFile(item.file, 'video/mp4')}
                            >
                                <source
                                    src={`data:video/mp4;base64,${item.file}`}
                                    type="video/mp4"
                                />
                                Your browser does not support the video tag.
                            </Box>
                        )}
                    </div>
                ))}
            </AutoPlaySwipeableViews>
            <MobileStepper
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                nextButton={
                    <Button
                        size="small"
                        onClick={handleNext}
                        disabled={activeStep === maxSteps - 1}
                    >
                        Next
                        {theme.direction === 'rtl' ? (
                            <KeyboardArrowLeft />
                        ) : (
                            <KeyboardArrowRight />
                        )}
                    </Button>
                }
                backButton={
                    <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                        {theme.direction === 'rtl' ? (
                            <KeyboardArrowRight />
                        ) : (
                            <KeyboardArrowLeft />
                        )}
                        Back
                    </Button>
                }
            />
        </Box>
    );
}

export default SlideImage;
