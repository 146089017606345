import React, { useState, useEffect, useContext } from "react";
import { connect } from 'react-redux';
import { dateConfig } from 'config';
import moment from 'moment';
// MUI
import { styled } from '@mui/material/styles';
import {
    Popover,
    Typography,
    IconButton,
    Stack,
    List,
    ListItem,
    ListItemButton,
    Badge,
} from '@mui/material';
// Icone
import { STATUTS_COLOR } from 'config';
import MessageIcon from '@mui/icons-material/Message';
// service
import { getLastMessageByClient, putMessageRead } from "services";
// context
import ChatContext from "contexts/ChatContext";
// lodash
import { filter } from 'lodash';

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: 1,
        top: 0,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}));

function AllChats(props) {
    const currentDate = Date.now();
    const ctx = useContext(ChatContext);
    const [read, setRead] = useState(false);
    const [message, setMessage] = useState([]);
    const [loading, setLoading] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [nbLastMessagesForBadge, setNbLastMessagesForBadge] = useState(0);
    const [nbLastMessagesNotAnswered, setNbLastMessagesNotAnswered] = useState(0);

    let attached_to = '' + props.attached_to;

    const routeChange = (message) => {
        if (!message.read) {
            putMessageRead({ id_mastore: message.id_mastore, read: true });
        }
        setRead(!read);
        window.location.href = '/intervention?IDMastore=' + message.id_mastore;
        handleClose();
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    useEffect(() => {
        Promise.all([
            getLastMessageByClient(attached_to)
        ]).then(([responseMessageByClient]) => {
            setMessage(responseMessageByClient.message);
            setNbLastMessagesForBadge(filter(responseMessageByClient.message, { 'send_by': 'client', 'read': false }).length);
            setNbLastMessagesNotAnswered(filter(responseMessageByClient.message, { 'send_by': 'client', 'answered': false }).length);
            setLoading(true);
        }).catch(err => {
            console.log(err);
        });
    }, [ctx.isNewMessage, read, attached_to, ctx.isOpenChat]);

    const HandleFormatDate = (date) => {
        let timeDif = currentDate - new Date(date).getTime();
        let minutes = Math.floor(timeDif / 60000);
        if (minutes < 1440) {
            return moment(date).format(dateConfig.format7);
        }
        if (minutes >= 1440 && minutes <= 525600) {
            return moment(date).format(dateConfig.format2);
        }
        if (minutes >= 525600) {
            return moment(date).format(dateConfig.format3);
        }
    };

    const handleStatusAvatar = (status) => {
        if (status === "EN COURS")
            return <Typography variant='subtitle2' color={STATUTS_COLOR[status]}>{status}</Typography>;
        if (status === "TERMINEE")
            return <Typography variant='subtitle2' color={STATUTS_COLOR[status]}>{status}</Typography>;
        if (status === "FACTUREE")
            return <Typography variant='subtitle2' color={STATUTS_COLOR[status]}>{status}</Typography>;
        else {
            return <Typography variant='subtitle2' color={"#2065d1"}>{status}</Typography>;
        }
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div>
            <StyledBadge badgeContent={nbLastMessagesNotAnswered} color="error">
                <StyledBadge badgeContent={nbLastMessagesForBadge} color="secondary">
                    <IconButton onClick={handleClick}>
                        <MessageIcon />
                    </IconButton>
                </StyledBadge>
            </StyledBadge>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                disableScrollLock={true}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                {loading && message.length > 0 ?
                    message.map((item, index) => (
                        <List key={index} sx={{ width: "350px", padding: 0, alignItems: "center" }}>
                            <ListItem disablePadding>
                                <ListItemButton
                                    sx={{ justifyContent: "center" }}
                                    style={!item.read && item.send_by === "client" ? { background: "#e3f2fd" } : undefined}
                                    onClick={() => routeChange(item)}
                                >
                                    <Stack alignItems="center" width={"100%"}>
                                        <Stack direction="row" justifyContent="space-between" width={"100%"}>
                                            <Typography variant='subtitle2' sx={{ color: "#65676b" }}>{item.id_mastore}</Typography>
                                            {handleStatusAvatar(item.status)}
                                        </Stack>
                                        <Typography variant='h6' width={"100%"}>{item.enseigne + " - " + item.ville + " N°[" + item.numero_magasin + "]"}</Typography>
                                        <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center" width={"100%"}>
                                            <Typography variant='body2' noWrap>{item.message} </Typography>
                                            <Typography variant='subtitle2' sx={{ color: "#65676b" }}>{HandleFormatDate(item.CreatedAt)}</Typography>
                                        </Stack>
                                    </Stack>
                                </ListItemButton>
                            </ListItem>
                        </List>
                    )) : <Typography variant='body1' sx={{ padding: "1rem" }}>Aucun message non répondu</Typography>
                }
            </Popover>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        attached_to: state.attached_to,
    };
};

export default connect(mapStateToProps, null)(AllChats);
