import React, { useEffect, useState } from "react";
import { Button, Box, Stack, Typography } from "@mui/material";
import CreateIcon from "@mui/icons-material/Create";
import { useSnackbar } from "notistack";
import Header from "components/UI/Header/Header";
import TableCommandeStock from "./TableCommandeStock";
import Alert from "@mui/material/Alert";
import CreateFicheCommandeStock from "./CreateFicheCommandeStock";
import axios from "axios";
import Modal from "components/UI/Modal";

import Mailbox from "views/Mail/Mail";
import ModalEditFicheCommande from "./ModalEditFicheCommande";

import StockService from "services/stocksService";

const stockService = new StockService();

export default function CommandeStocks({ infoIntervention, user, actions }) {
  const [openCreateFicheCommandeStock, setOpenCreateFicheCommandeStock] =
    useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [ficheCommandes, setFicheCommandes] = useState([]);
  const [attachment] = useState([]);

  const [sendMailBool, setSendMailBool] = useState(false);
  const [filteredMail, setFilteredMail] = React.useState([]);
  const [alert, setAlert] = useState(null);
  const [filteredIntervenant, setFilteredIntervenant] = useState([]);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [filter, setFilter] = React.useState({
    societe: null,
    specialite: null,
    ville: null,
    distance: 50,
  });

  useEffect(() => {
    axios.defaults.withCredentials = true;
    axios.get("/legacy/GetIntervenants").then((response) => {
      const emailArray = response.data.artisan
        .map((artisan) => artisan.mail)
        .flat();

      setFilteredMail(emailArray);
      setFilteredIntervenant(response.data.artisan);
    });
  }, []);

  const handleCreateFicheCommandeStockModal = () => {
    setOpenCreateFicheCommandeStock(!openCreateFicheCommandeStock);
  };

  const deleteFicheCommandeStock = async (idFiche) => {
    // Appel à l'API pour supprimer la fiche de commande
    await stockService.deleteFicheCommande(idFiche);
    // Mise à jour de la liste des fiches de commande
    stockService
      .getFicheCommande(infoIntervention.id_mastore)
      .then((data) => setFicheCommandes(data))
      .catch((error) => {
        enqueueSnackbar("Erreur lors de la récupération des données.", {
          variant: "error",
        });
      });
  };

  const handleEditFicheCommande = (row) => {
    setRowToEdit(row);
    setOpenEditModal(true);
  };

  const handleUpdateFicheCommande = async (updatedFicheCommande) => {
    try {
      await stockService.putFicheCommande(updatedFicheCommande);
      setOpenEditModal(false);
      enqueueSnackbar("Fiche de commande mise à jour avec succès.", {
        variant: "success",
      });
      stockService
        .getFicheCommande(infoIntervention.id_mastore)
        .then((data) => setFicheCommandes(data))
        .catch((error) => {
          enqueueSnackbar("Erreur lors de la récupération des données.", {
            variant: "error",
          });
        });
    } catch (error) {
      console.error(
        "Erreur lors de la mise à jour de la fiche de commande : ",
        error
      );
      enqueueSnackbar(
        "Erreur lors de la mise à jour de la fiche de commande.",
        { variant: "error" }
      );
    }
  };

  useEffect(() => {
    stockService
      .getFicheCommande(infoIntervention.id_mastore)
      .then((data) => {
        setFicheCommandes(data);
      })
      .catch((error) => {
        enqueueSnackbar("There was an error fetching the data!", {
          variant: "error",
        });
        console.error(error);
      });
  }, [enqueueSnackbar, infoIntervention.id_mastore]);

  const stateModalSendMail = (event, row) => {
    if (row.file) {
      setAlert(
        <Alert
          style={{
            margin: "auto",
            marginBottom: "20px",
            border: "1px solid",
            width: "50%",
          }}
          severity="warning"
        >
          Sauvegarder la demande de devis et l'intervention pour pouvoir envoyé
          le mail !
        </Alert>
      );
    } else {
      setSendMailBool(true);
      setAlert(null);
    }
  };

  const onChangeFilter = (value, key) => {
    setFilter({ ...filter, [key]: value });
  };


  const handleClose = () => {
    setSendMailBool(false);
  };

  const objectMail = `Fiche de commande - Enseigne : ${infoIntervention.enseigne} N°${infoIntervention.numero_magasin} - DI Mastore : ${infoIntervention.id_mastore} - DI Client : ${infoIntervention.id_client} - ${infoIntervention.ville} [${infoIntervention.code_postal}] - ${infoIntervention.specialite}`;

  return (
    <Box
      sx={{
        width: { xl: "65vw", md: "70vw", sm: "70vw", xs: "95vw" },
        maxHeight: "80vh",
        padding: "5px",
      }}
    >
      <Header>Commande Stocks</Header>
      {alert}
      <Stack sx={{ px: 3, mb: 2, overflow: "auto" }} spacing={1}>
        <Stack direction="row" spacing={1}>
          <Button
            variant="outlined"
            onClick={handleCreateFicheCommandeStockModal}
            startIcon={<CreateIcon />}
            disabled={user.role === "Assistant(e)" || user.role === "Utilisateur"} // Désactiver pour les rôles spécifiés
          >
            Créer une fiche commande
          </Button>

        </Stack>
        <Stack style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
          {ficheCommandes.length > 0 ? (
            <TableCommandeStock
              data={ficheCommandes}
              infoIntervention={infoIntervention}
              deleteCommandeStock={deleteFicheCommandeStock}
              mailInter={filteredMail}
              actions={actions}
              modalSendMail={stateModalSendMail}
              handleEditFicheCommande={handleEditFicheCommande}
              user={user}
            />) : (
            <Typography variant="h1" align="center" textAlign={"center"} color="rgba(99, 115, 129, 0.3)">
              Aucune fiches de commande
            </Typography>)}
        </Stack>



        <Modal
          open={openCreateFicheCommandeStock}
          close={handleCreateFicheCommandeStockModal}
          padding="0px"
        >
          <CreateFicheCommandeStock
            infoInternvention={infoIntervention}
            filteredIntervenant={filteredIntervenant}
            filter={filter}
            user={user}
          />
        </Modal>
        <Modal open={sendMailBool} close={handleClose}>
          <Box
            sx={{
              p: 2,
              display: "flex",
              alignItems: "center",
              backgroundColor: "white",
              margin: "auto",
              mt: 4,
              borderRadius: 1,
              outline: "none",
              width: "80%",
              height: "90%",
            }}
          >
            <Mailbox
              objectMail={objectMail}
              Attachment={attachment}
              modalClosed={handleClose}
              destinataire={actions}
              infoIntervention={infoIntervention}
              attribut="Fiche Commande"
              mailuser={user}
              sendto="intervenant"
            />
          </Box>
        </Modal>
        <Modal open={openEditModal} onClose={() => setOpenEditModal(false)}>
          <ModalEditFicheCommande
            row={rowToEdit}
            handleUpdateFicheCommande={handleUpdateFicheCommande}
            close={() => setOpenEditModal(false)}
          />
        </Modal>
      </Stack>
    </Box>
  );
}
