import React from 'react';
import { MobileDatePicker } from '@mui/x-date-pickers';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import { TextField } from '@mui/material';

// ----------------------------------------------------------------------

const StyledTextField = styled(TextField)(() => ({
    width: "100%",
    '.MuiInputBase-input': {
        padding: "5px 10px !important",
    },
}));

// ----------------------------------------------------------------------

const TableMobileDatePicker = (props) => {
    const value = props.value ? moment(props.value) : null;

    return (
        <MobileDatePicker
            {...props}
            format={props.inputFormat}
            value={value}
            closeOnSelect
            slots={{
                textField: StyledTextField,
            }}
            slotProps={{
                textField: {
                    value: value,
                },
            }}
        />
    );
};

export default React.memo(TableMobileDatePicker);
